import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Card, Text, Flex, HStack, Button, Box, Grid, VStack, Avatar, useToast } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { renderAsync } from 'docx-preview';
import { Document, Page, pdfjs } from 'react-pdf';
import { Buffer } from 'buffer';
import { fileTypeFromBuffer } from 'file-type';
import { createRoot } from 'react-dom/client';
import { ReactComponent as LabelIcon } from '../../images/label.svg';
import { ReactComponent as ArrowBackIcon } from '../../images/arrow-back.svg';

import './index.scss';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs`;

const PrepareAnalysis = () => {
    const navigate = useNavigate();
    const { caseID } = useParams();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [files, setFiles] = useState([]);
    const previewContainerRef = useRef(null);
    const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const toast = useToast();

    useEffect(() => {
        fetchFilesAndVersions();
    }, []);

    useEffect(() => {
        if (selectedFile && selectedVersion) {
            loadFilePreview();
        }
    }, [selectedFile, selectedVersion]);

    const fetchFilesAndVersions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_casefiles`, {
                params: {
                    case_id: caseID,
                    list_with_versions: true
                }
            });
            setFiles(response.data.files);
            setSelectedFile(response.data.files[0]);
            setSelectedVersion(response.data.files[0].versions[0]);
        } catch (error) {
            console.error('Error fetching files and versions:', error);
        }
    };

    const handleFileSelect = (file) => {
        setSelectedFile(file);
        setSelectedVersion(file.versions[0]);
    };

    const handleVersionSelect = (e, version) => {
        e.stopPropagation();
        setSelectedVersion(version);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const loadFilePreview = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/stream_casefile`,
                {
                    file_id: selectedFile.file_id,
                    version_id: selectedVersion.version_id,
                    case_id: caseID
                },
                { responseType: 'arraybuffer' }
            );

            const arrayBuffer = response.data;
            const buffer = Buffer.from(arrayBuffer);
            const fileType = await fileTypeFromBuffer(buffer);

            if (fileType.mime === 'application/pdf') {
                // Clear previous content
                if (previewContainerRef.current) {
                    previewContainerRef.current.innerHTML = '';
                }
                
                // Create blob URL for PDF
                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                
                // Create and append PDF viewer
                const pdfViewer = document.createElement('div');
                pdfViewer.style.width = '100%';
                pdfViewer.style.height = '100%';
                previewContainerRef.current.appendChild(pdfViewer);

                // Create root and render PDF using react-pdf
                const root = createRoot(pdfViewer);
                root.render(
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        ))}
                    </Document>
                );

            } else if (fileType.mime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                // Handle DOCX preview
                if (previewContainerRef.current) {
                    await renderAsync(arrayBuffer, previewContainerRef.current);
                }
            } else {
                console.error('Unsupported file type');
            }
        } catch (error) {
            console.error('Error loading file preview:', error);
        }
    };

    const handleStartAnalysis = async () => {
        try {
            setIsAnalysisLoading(true);
            const analysisData = files.map(file => ({
                file_id: file.file_id,
                tag: file.tag,
                version_id: file.versions.find(v => v.version_id === selectedVersion?.version_id)?.version_id || file.versions[0].version_id,
                isOCRProcessed: selectedVersion?.original_filename?.toLowerCase().endsWith('.pdf') ? true : false
            }));
            
            const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/multi_doc_analysis`, {
                case_id: caseID,
                files: analysisData
            });

            setIsAnalysisLoading(false);
            navigate(`/dispute_analysis/${caseID}/${response.data.analysis_id}`);
        } catch (error) {
            console.error('Error starting analysis:', error);
            setIsAnalysisLoading(false);
            toast({
                title: "Error",
                description: "Failed to start the analysis. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <div className="page-content-margin">
            <Container maxW={'container.xl'} mt={8}>
                <Flex>
                    <HStack>
                        <Button variant={'ghost'} onClick={() => navigate(`/case/${caseID}`)}>
                            <ArrowBackIcon fill='var(--chakra-colors-gray-600)' />
                            <Text ml={2} fontSize={'1.1rem'} fontWeight={'600'} color={'gray.600'}>Go Back</Text>
                        </Button>
                    </HStack>
                </Flex>
                <Box p={[4, 6]}>
                    <Text variant={'page_title'} mb={4}>
                        Prepare Analysis
                    </Text>
                    <Flex>
                        <VStack align="stretch" spacing={4}>
                            <AnimatePresence>
                                {files.map((file) => (
                                    <motion.div
                                        key={file._id}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <Card
                                            p={4}
                                            borderWidth={selectedFile?._id === file._id ? "2px" : "1px"}
                                            borderColor={selectedFile?._id === file._id ? "black" : "gray.200"}
                                            onClick={() => handleFileSelect(file)}
                                            cursor="pointer"
                                        >
                                            <VStack align={'flex-start'} spacing={2}>
                                                <HStack spacing={2} bg={'gray.200'} py={1} px={3} borderRadius={'md'}>
                                                    <LabelIcon height={'20px'} width={'20px'} fill='#2d3748' />
                                                    <Text color={'gray.700'} fontSize={'1rem'} fontWeight={'600'} maxWidth={'280px'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>
                                                        {file.tag}
                                                    </Text>
                                                </HStack>
                                                {file.versions.length == 1 && <HStack mt={1}>
                                                    <Avatar size={'xs'} src={file.uploaded_by.profile_picture_url} />
                                                    <Text color={'gray.700'} fontSize={'0.8rem'}>{file.versions[0].uploaded_by.full_name}</Text>
                                                    <Text fontSize={'1.2rem'}>·</Text>
                                                    <Text fontSize={'0.8rem'} color="gray.500">
                                                        {moment(file.versions[0].created_at * 1000).fromNow()}
                                                    </Text>
                                                </HStack>}
                                                {selectedFile?._id === file._id && file.versions.length > 1 && (
                                                    <motion.div
                                                        style={{ width: '100%' }}
                                                        initial={{ opacity: 0, height: 0 }}
                                                        animate={{ opacity: 1, height: 'auto' }}
                                                        exit={{ opacity: 0, height: 0 }}
                                                        transition={{ duration: 0.3 }}
                                                    >
                                                        <VStack width={'100%'} align="stretch" spacing={2} mt={2}>
                                                            <Text fontSize={'0.8rem'} mt={2} fontWeight={'600'} color={'gray.600'}>VERSIONS</Text>
                                                            {file.versions.map((version, index) => (

                                                                <Flex
                                                                    key={index}
                                                                    py={2}
                                                                    px={4}
                                                                    borderWidth="2px"
                                                                    borderColor={selectedVersion?.version_id === version.version_id ? "#00B870" : 'gray.200'}
                                                                    bg={selectedVersion?.version_id === version.version_id ? "green.50" : "white"}
                                                                    borderRadius="md"
                                                                    alignItems="center"
                                                                    justifyContent={'space-between'}
                                                                    cursor="pointer"
                                                                    onClick={(e) => handleVersionSelect(e, version)}
                                                                >
                                                                    <VStack align={'flex-start'}>
                                                                        <HStack spacing={1}>
                                                                            <Text fontSize={'1.1rem'} color={selectedVersion?.version_id === version.version_id ? "#000" : "gray.500"}>{version.version_name}
                                                                            </Text>
                                                                        </HStack>
                                                                        <HStack>

                                                                            <HStack>
                                                                                <Avatar size={'xs'} src={version.uploaded_by.profile_picture_url} />
                                                                                <Text color={'gray.700'} fontSize={'0.8rem'}>
                                                                                    {version.uploaded_by.full_name}
                                                                                </Text>
                                                                                <Text fontSize={'1.2rem'}>·</Text>
                                                                                <Text fontSize={'0.8rem'} color="gray.500">
                                                                                    {moment(version.created_at * 1000).fromNow()}
                                                                                </Text>
                                                                            </HStack>
                                                                        </HStack>
                                                                    </VStack>
                                                                    <Box
                                                                        width="20px"
                                                                        height="20px"
                                                                        borderRadius="50%"
                                                                        borderWidth="2px"
                                                                        borderColor={selectedVersion?.version_id === version.version_id ? "#00B870" : "gray.300"}
                                                                        position="relative"
                                                                    >
                                                                        {selectedVersion?.version_id === version.version_id && (

                                                                            <Box
                                                                                position="absolute"
                                                                                top="50%"
                                                                                left="50%"
                                                                                transform="translate(-50%, -50%)"
                                                                                width="10px"
                                                                                height="10px"
                                                                                borderRadius="50%"
                                                                                bg="#00B870"
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Flex>
                                                            ))}
                                                        </VStack>
                                                    </motion.div>
                                                )}
                                            </VStack>
                                        </Card>
                                    </motion.div>
                                ))}
                            </AnimatePresence>
                            <Button position="sticky" bottom="16px" left="0" right="0" zIndex="1" isLoading={isAnalysisLoading} variant={'default'} onClick={handleStartAnalysis}>Start Analysis</Button>
                        </VStack>
                        <Card position="sticky" top="36px" height="calc(100vh - 166px)" ml={'24px'} overflowY="auto" p={4}>
                            {selectedFile ? (
                                <VStack align="stretch" spacing={4}>
                                    {selectedVersion && <Text fontWeight="bold" color={'gray.700'} fontSize={'1.2rem'}>{selectedVersion.original_filename}</Text>}
                                    <Box className='prepare-analysis-preview-container' ref={previewContainerRef} maxH={'calc(100vh - 250px)'} borderRadius={'md'} height="100%" overflowY="auto" />
                                </VStack>
                            ) : (
                                <Text>Select a file to preview</Text>
                            )}
                        </Card>
                    </Flex>
                </Box>
            </Container>
        </div>
    );
};

export default PrepareAnalysis;
