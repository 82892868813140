import React, { useState, useEffect} from "react"
import { useParams } from 'react-router-dom';
import axios from "axios";
import { Container, VStack, Button, Box } from "@chakra-ui/react";

import TypeSelect from '../../components/TypeSelect';
import DraftRespondentPleading from "../../components/DraftRespondentPleading";

import { ReactComponent as ExecutiveSummaryIcon } from '../../images/executive_summary.svg';
import { ReactComponent as NotesIcon } from '../../images/notes.svg';
import { ReactComponent as RejoinderIcon } from '../../images/respondent_pleading.svg';
import { ReactComponent as ReplyIcon } from '../../images/rejoinder.svg';

const DeliverablesTabNew = () => {
    const { caseID } = useParams();
    const { analysisID } = useParams();

    const [allDrafts, setAllDrafts] = useState([]);
    const [documentType, setDocumentType] = useState('');
    const [showDraftComponent, setShowDraftComponent] = useState(false);
    
    useEffect(() => {
        fetchAllDrafts();
    }, []);

    const fetchAllDrafts = async () => {
        const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_drafts`;
        const response = await axios.get(url);
        setAllDrafts(response.data.drafts);
    }

    const createNewDraft = async () => {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/new_draft`;
      const response = await axios.put(url, {
        case_id: caseID,
        draft_type: 'respondent_pleading'
      });
      console.log('RESPONSE', response);
      setShowDraftComponent(true);
    }
    
    return (
        <>
          {!allDrafts.length && !showDraftComponent && (
        <Container mt={12}>
            <VStack spacing={4} align="stretch">
              <TypeSelect
                title={'Select Deliverable Type'}
                options={[
                  {
                    title: 'Executive Summary',
                    type: 'executive_summary',
                    description:
                      'Construct documentation capturing the high level overview of the dispute',
                    icon: ExecutiveSummaryIcon,
                  },
                  {
                    title: 'Notes',
                    type: 'notes',
                    description:
                      'Capture precise information to share with key stakeholders',
                    icon: NotesIcon,
                  },
                  {
                    title: "Points for Respondent's Pleading",
                    type: 'respondent_pleading',
                    description:
                      'Strengthen the defense and eliminate gaps by drafting key factual points',
                    icon: RejoinderIcon,
                  },
                  {
                    title: 'Points for Rejoinders',
                    type: 'rejoinder',
                    description:
                      'Gather key points from existing and additional documents to convert them into subsequent pleadings',
                    icon: ReplyIcon,
                  },
                ]}
                onChange={doc_type => setDocumentType(doc_type)}
              />
              <Button
                variant={'default'}
                onClick={createNewDraft}
              >
                Next
              </Button>
            </VStack>
            </Container>
          )}
          
          {showDraftComponent && (
              <DraftRespondentPleading 
                caseID={caseID} 
                analysisID={analysisID} 
              />
          )}
        </>
    )
}

export default DeliverablesTabNew