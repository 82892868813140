import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  ChakraProvider
} from '@chakra-ui/react';
import theme from './theme';
import axios from 'axios';
import { StatsigProvider } from "@statsig/react-bindings";

import Header from './components/Header'
import VerifyLogin from './modules/VerifyLogin'
import JoinWorkspace from './modules/JoinWorkspace';
import Home from "./modules/Home"
import Logout from "./modules/Logout"
import AnalysisHistory from "./modules/AllCases"
import DisputeAnalysisResults from './modules/DisputeAnalysisResults';
import CaseView from './modules/CaseView';
import Settings from './modules/Settings'
import Onboarding from './modules/Onboarding/index'
import PrepareAnalysis from './modules/PrepareAnalysis'
import Triage from './modules/Triage'
import DashboardOverview from './modules/DashboardOverview'
import NewDashboard from './modules/NewDashboard'
import Dashboard from './modules/Dashboard'
import NewTriageLabel from './modules/NewTriageLabel'
import { getCookie } from './helpers/cookies';
import { AuthContext } from './helpers/AuthContext';
import './styles/index.scss'
import { NoteTakerStateProvider } from './components/ProseMirror/NoteTakerState';

const App = () => {
  const [globalState, setGlobalState] = useState({ errors: [] });
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [client, setClient] = useState(null);

  // Move StatsigProvider outside until we have user data
  const content = (
    <Router>
      <AuthContext.Provider value={{ userData, setUserData, token, setToken, isLoading, setIsLoading }}>
        <ErrorHandler />
        <NoteTakerStateProvider>
          <AuthCheck>
            <Header />
            <Routes>
              <Route path="/" element={<Home globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/verify" element={<VerifyLogin/>} />
              <Route path="/join" element={<JoinWorkspace/>} />
              <Route path="/logout" element={<Logout globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/case_list" element={<AnalysisHistory globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/dispute_analysis/:caseID/:analysisID" element={<DisputeAnalysisResults globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/settings" element={<Settings globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/case/:caseID" element={<CaseView globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/prepare_analysis/:caseID" element={<PrepareAnalysis globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/triage/" element={<Triage globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/triage/new" element={<NewTriageLabel globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/dashboard" element={<DashboardOverview globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/dashboard/new" element={<NewDashboard globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/dashboard/:dashboardID" element={<Dashboard globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="*" element={<><h1> Page not found</h1></>} />
            </Routes>
          </AuthCheck>
        </NoteTakerStateProvider>
      </AuthContext.Provider>
    </Router>
  );

  return (
    <ChakraProvider theme={theme}>
      {userData ? (
        <StatsigProvider
          sdkKey="client-4XmrQg7nUeXKSxS48USLwf8AZsuyN4aVVYXwKvBd9gq"
          waitForInitialization={true}
          user={{
            userID: userData.uid,
            email: userData.email,
          }}>
          {content}
        </StatsigProvider>
      ) : (
        content
      )}
    </ChakraProvider>
  );
};


const ErrorHandler = () => {
  const { globalState, setGlobalState } = useContext(AuthContext);

  useEffect(() => {
    if (globalState?.errors && globalState?.errors?.length > 0) {
      globalState.errors.forEach((error) => {
        alert(error.message);
      });
      setGlobalState((prevState) => ({ ...prevState, errors: [] }));
    }
  }, [globalState, setGlobalState]);

  return null;
};


const AuthCheck = ({ children }) => {
  const { userData, setUserData, token, setToken, isLoading, setIsLoading } = useContext(AuthContext);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTokenAndUserData = async () => {
      const tokenFromCookie = getCookie('auth-token');
      const exemptRoutes = ['/logout', '/', '/verify', '/join', '/onboarding'];
      
      if (!exemptRoutes.includes(window.location.pathname)) {
        if (tokenFromCookie) {
          setToken(tokenFromCookie);
          axios.defaults.headers.common['auth-token'] = tokenFromCookie;

          try {
            const url = `${process.env.REACT_APP_BASE_PATH}/v1/user`;
            const response = await axios.get(url);
            setUserData(response.data);
            if (!response.data.is_onboarded) {
              navigate('/onboarding');
              return;
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
            navigate('/logout');
            return;
          }
        } else {
          navigate('/logout');
          return;
        }
      }
      setIsLoading(false);
      setIsSetupComplete(true);
    };

    fetchTokenAndUserData();
  }, [navigate, setIsLoading, setToken, setUserData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return children;
};


export default App;
