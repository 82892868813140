import React, { useState, useCallback, useMemo } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTable, useResizeColumns, useBlockLayout, useSortBy } from 'react-table';
import { Container, Heading, Box, Badge, Button, HStack, Text } from '@chakra-ui/react';
import './index.scss';

import { ReactComponent as DocIcon } from '../../images/doc.svg';
import { ReactComponent as CategoryIcon } from '../../images/metadata_icons/category.svg';
import { ReactComponent as ClaimValueIcon } from '../../images/metadata_icons/claim_value.svg';
import { ReactComponent as RiskIcon } from '../../images/warning.svg';
import { ReactComponent as SummaryIcon } from '../../images/info.svg';

const Dashboard = () => {
    const data = useMemo(() =>
        [
            {
                id: 1,
                case: "Terraforma vs Athena microsystems",
                risk: "Very high",
                claimValue: "$8 Million",
                summary: "Potential for bad PR",
                disputeCategory: "Contractual"
            },
            {
                id: 2,
                case: "Titan Industries vs Athena Microsystem",
                risk: "High",
                claimValue: "$2.21 Million",
                summary: "Names key employees",
                disputeCategory: "Contractual"
            },
            {
                id: 3,
                case: "State of Ohio vs Athena Microsystem",
                risk: "Moderate",
                claimValue: "$10.1 Million",
                summary: "Tax liability dispute",
                disputeCategory: "Tax Liability"
            },
            {
                id: 4,
                case: "Fabtech Foundry vs Athena Microsystem",
                risk: "Moderate",
                claimValue: "$102k",
                summary: "Suit filed by key vendor",
                disputeCategory: "Contractual"
            },
            {
                id: 5,
                case: "The XLO vs Athena Microsystem",
                risk: "Low",
                claimValue: "$3.8 Million",
                summary: "Competitor has filed a frivolous lawsuit",
                disputeCategory: "Commercial Dispute"
            }
        ], []);

    // Calculate max widths based on content length
    const getMaxWidth = (accessor) => {
        return Math.max(
            ...data.map(row => String(row[accessor]).length * 9), // Approximate character width
            accessor.length * 8 // Header length
        );
    };

    const allColumns = useMemo(() => [
        {
            Header: '#',
            accessor: 'id',
            width: 50,
            Cell: ({ value }) => (
                <Text>{value}</Text>
            ),
        },
        {
            Header: () => (
                <HStack spacing={1}>
                    <DocIcon fill='#000' height={16} width={16} />
                    <Text>Case Name</Text>
                </HStack>
            ),
            accessor: 'case',
            width: getMaxWidth('case'),
            sticky: 'left',
            Cell: ({ value }) => (
                <Badge variant={'case_name'} px={2} py={1} borderRadius="4px" fontSize={'12px'} cursor={'pointer'}>
                    <HStack spacing={1}>
                        <DocIcon style={{ display: 'inline-block', marginRight: '4px' }} height={16} width={16} fill='#000' />
                        <Text color={'gray.900'}>{value}</Text>
                    </HStack>
                </Badge>
            ),
        },
        {
            Header: () => (
                <HStack spacing={1}>
                    <RiskIcon fill='#000' height={16} width={16} />
                    <Text>Risk</Text>
                </HStack>
            ),
            accessor: 'risk',
            minWidth: 120,
            width: 120,
            Cell: ({ value }) => (
                <Badge colorScheme={getRiskColor(value)} px={2} py={1} borderRadius="4px">
                    {value}
                </Badge>
            ),
        },
        {
            Header: () => (
                <HStack spacing={1}>
                    <SummaryIcon fill='#000' height={16} width={16} />
                    <Text>Risk Summary</Text>
                </HStack>
            ),
            accessor: 'summary',
            width: getMaxWidth('summary'),
            Cell: ({ value }) => (
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {value}
                </div>
            ),
        },
        {
            Header: () => (
                <HStack spacing={1}>
                    <ClaimValueIcon fill='#000' height={16} width={16} />
                    <Text>Claim Value</Text>
                </HStack>
            ),
            accessor: 'claimValue',
            minWidth: 150,
            width: 150,
        },
        {
            Header: () => (
                <HStack spacing={1}>
                    <CategoryIcon fill='#000' height={16} width={16} />
                    <Text>Dispute Category</Text>
                </HStack>
            ),
            accessor: 'disputeCategory',
            minWidth: 200,
            width: 200,
        },
    ], []);

    // Define which columns should be visible (this would come from API later)
    const visibleColumnAccessors = ['id', 'case', 'risk', 'disputeCategory', 'claimValue', 'summary']; // Example: only show these columns

    const [records, setRecords] = useState(data);

    const columns = useMemo(() => 
        allColumns.filter(column => visibleColumnAccessors.includes(column.accessor))
    , []);

    const getRiskColor = (risk) => {
        switch (risk.toLowerCase()) {
            case 'very high': return 'red';
            case 'high': return 'orange';
            case 'moderate': return 'yellow';
            case 'low': return 'green';
            default: return 'gray';
        }
    };

    const DraggableRow = ({ index, moveRow, ...props }) => {
        const dropRef = React.useRef(null);
        const dragRef = React.useRef(null);

        const [, drop] = useDrop({
            accept: 'row',
            hover(item, monitor) {
                if (!dropRef.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }
                moveRow(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        });

        const [{ isDragging }, drag, preview] = useDrag({
            type: 'row',
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        preview(drop(dropRef));
        drag(dragRef);

        return (
            <tr ref={dropRef} style={{ opacity: isDragging ? 0.5 : 1 }} {...props}>
                <td ref={dragRef} style={{ cursor: 'move' }}>⋮</td>
                {props.children}
            </tr>
        );
    };

    const moveRow = useCallback((dragIndex, hoverIndex) => {
        setRecords((prevRecords) => {
            const newRecords = [...prevRecords];
            const [reorderedItem] = newRecords.splice(dragIndex, 1);
            newRecords.splice(hoverIndex, 0, reorderedItem);
            return newRecords;
        });
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: records,
        },
        useBlockLayout,
        useResizeColumns,
        useSortBy
    );

    return (
        <DndProvider backend={HTML5Backend}>
            <Container maxW="container.xl" py={8}>
                <HStack justifyContent={'space-between'} alignItems={'center'} mb={8}>
                    <Heading size="lg" fontFamily={'Garnett'} fontWeight={'600'} color={'gray.900'}>
                        March Dispute Briefing
                    </Heading>
                    <HStack spacing={4}>
                        <Button variant={'outline'}>
                            Export
                        </Button>
                        <Button variant={'secondary'}>
                            Share
                        </Button>
                    </HStack>
                </HStack>
                <Box className="table" overflowX="auto" {...getTableProps()}>
                    <div>
                        {headerGroups.map(headerGroup => (
                            <div {...headerGroup.getHeaderGroupProps()} className="tr">
                                {headerGroup.headers.map(column => (
                                    <div 
                                        {...column.getHeaderProps(column.getSortByToggleProps())} 
                                        className="th"
                                        style={{
                                            ...column.getHeaderProps().style,
                                            position: column.sticky ? 'sticky' : 'relative',
                                            left: column.sticky ? 0 : 'auto',
                                            background: 'white',
                                            zIndex: column.sticky ? 1 : 0
                                        }}
                                    >
                                        {column.render('Header')}
                                        <div
                                            {...column.getResizerProps()}
                                            className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div {...getTableBodyProps()}>
                        {rows.map((row, index) => {
                            prepareRow(row);
                            return (
                                <DraggableRow index={index} moveRow={moveRow} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <div 
                                                {...cell.getCellProps()} 
                                                className="td"
                                                style={{
                                                    ...cell.getCellProps().style,
                                                    position: cell.column.sticky ? 'sticky' : 'relative',
                                                    left: cell.column.sticky ? 0 : 'auto',
                                                    background: 'white',
                                                    zIndex: cell.column.sticky ? 1 : 0
                                                }}
                                            >
                                                {cell.render('Cell')}
                                            </div>
                                        );
                                    })}
                                </DraggableRow>
                            );
                        })}
                    </div>
                </Box>
            </Container>
        </DndProvider>
    );
};

export default Dashboard;
