import { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import DashboardSheet from '../../components/DashboardSheet';
import { ReactComponent as DeleteIcon } from '../../images/delete.svg';
import { ReactComponent as ChevronRightIcon } from '../../images/chevron_right.svg';
import { ReactComponent as TriageIcon } from '../../images/triage.svg';

import {
  Box,
  Container,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  Card,
  Divider,
  Flex,
  useToast,
  Text,
  IconButton,
  Collapse,
  Grid,
  GridItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Textarea
} from '@chakra-ui/react';
import SelectDropdown from '../../components/SelectDropdown';
import { ReactComponent as SendIcon } from '../../images/send.svg';
import { ReactComponent as AddColumnIcon } from '../../images/add_column.svg';
import './index.scss';

const NewDashboard = () => {
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardConfig, setDashboardConfig] = useState(null);
  const [dashboardId, setDashboardId] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [filtersFromQuery, setFiltersFromQuery] = useState({});
  const [possibleValues, setPossibleValues] = useState({});
  const [isExpanded, setIsExpanded] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [isAddColumnOpen, setIsAddColumnOpen] = useState(false);
  const [newColumnName, setNewColumnName] = useState('');
  const [newColumnType, setNewColumnType] = useState(null);
  const [newColumnInstruction, setNewColumnInstruction] = useState('');
  const [isAddingColumn, setIsAddingColumn] = useState(false);
  const [customColumns, setCustomColumns] = useState([]);
  const toast = useToast();

  const handleSearch = async () => {
    if (!query.trim()) {
      toast({
        title: "Query is required",
        description: "Please enter a search query to create your dashboard",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    setShowSuggestions(false);
    
    try {
      // const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/mock_text_to_filters`, { query });
      const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/text_to_filters`, { query });
      console.log(response.data);
      setFiltersFromQuery(response.data.filters || {});
      setDashboardId(response.data.dashboard_id);
      setPossibleValues(response.data.possible_values || {});

      setDashboardConfig({
        query,
        title: `Dashboard for "${query}"`,
        description: `Custom dashboard created based on your query: ${query}`
      });
    } catch (error) {
      toast({
        title: "Error processing query",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateDashboard = async () => {
    setIsLoading(true);
    setFiltersApplied(true);
    setIsExpanded(false);
    
    try {
      // const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/mock_get_dashboard_data`, {
        const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/get_dashboard_data`, {
        dashboard_id: dashboardId
      });
      setDashboardData(response.data);
    } catch (error) {
      toast({
        title: "Error fetching dashboard data",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  // Prepare data for DashboardSheet
  const prepareColumns = () => {
    if (!dashboardData || !dashboardData.dashboard.results.length) return [];

    // Extract all possible keys from the first result
    const firstResult = dashboardData.dashboard.results[0];
    return Object.keys(firstResult).map(key => ({
      Header: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
      accessor: key,
      width: 150,
      Cell: ({ value }) => {
        if (typeof value === 'object' && value !== null) {
          return JSON.stringify(value);
        }
        return String(value);
      }
    }));
  };

  const FilterVerificationStep = () => {
    const [filters, setFilters] = useState({ ...filtersFromQuery });

    const handleFilterChange = (key, value) => {
      setFilters(prev => ({
        ...prev,
        [key]: value
      }));
    };

    const handleRemoveFilter = (key) => {
      setFilters(prev => {
        const newFilters = { ...prev };
        delete newFilters[key];
        return newFilters;
      });
    };

    const handleApplyFilters = () => {
      setFiltersFromQuery(filters);
      handleCreateDashboard();
    };

    const formatOptionsForDropdown = (options) => {
      if (Array.isArray(options)) {
        // Handle simple array of strings
        if (typeof options[0] === 'string') {
          return options.map(option => ({
            id: option,
            option: option
          }));
        }
        // Handle array of objects with id and value
        return options.map(option => ({
          id: option.id || option.value,
          option: option.value
        }));
      }
      return [];
    };

    const getOptionsForField = (fieldName) => {
      const normalizedFieldName = fieldName.toLowerCase();

      // Check if the field exists directly in possibleValues
      if (possibleValues[normalizedFieldName]) {
        return formatOptionsForDropdown(possibleValues[normalizedFieldName]);
      }

      // For subcategory, we need to check the category first
      if (normalizedFieldName === 'subcategory' && filters.category) {
        const categoryKey = filters.category.toLowerCase().replace(/ /g, '_');
        if (possibleValues.subcategory && possibleValues.subcategory[categoryKey]) {
          return formatOptionsForDropdown(possibleValues.subcategory[categoryKey]);
        }
      }

      return [];
    };

    const renderFilterInput = (key, value) => {
      const options = getOptionsForField(key);

        return (
            <SelectDropdown
              options={options}
              onChange={(selectedValue) => handleFilterChange(key, selectedValue)}
              selectedValue={options.find(opt => opt.id === value || opt.option === value)}
              placeholderText={`Select ${key.replace(/_/g, ' ')}`}
              isMultiSelect={false}
              shouldShowAssignee={false}
              shouldShowSearch={true}
              isClearable={true}
            />
        );
    };

    return (
      <Box>
        <Heading size="md" mb={4}>Review Filters</Heading>

        {Object.keys(filters).length > 0 ? (
          <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={4} mb={4}>
            {Object.entries(filters).map(([key, value], index) => (
              <GridItem key={key}>
                <Box p={3} borderWidth="1px" borderRadius="md" bg="gray.50">
                  <VStack align="flex-start" spacing={2}>
                    <Text color="black" fontWeight="bold" textTransform="capitalize">
                      {key.replace(/_/g, ' ')}
                    </Text>
                    <Flex width="100%" justify="space-between" align="center">
                      <Box flex="1">{renderFilterInput(key, value)}</Box>
                      <IconButton
                        aria-label="Remove filter"
                        icon={<DeleteIcon fill="var(--chakra-colors-red-500)" />}
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                        onClick={() => handleRemoveFilter(key)}
                      />
                    </Flex>
                  </VStack>
                </Box>
              </GridItem>
            ))}
          </Grid>
        ) : (
          <Box py={4} textAlign="center">
            <Text color="gray.500">No filters applied</Text>
          </Box>
        )}

        <Flex justify="end" mt={6}>
          <Button
            colorScheme="green"
            onClick={handleApplyFilters}
            isLoading={isLoading}
            size="md"
          >
            Apply Filters
          </Button>
        </Flex>
      </Box>
    );
  };

  const columns = dashboardData ? prepareColumns() : [];
  const visibleColumnAccessors = columns.map(col => col.accessor);

  const columnTypeOptions = [
    { id: 'value', option: 'Value' },
    { id: 'summary', option: 'Summary' },
    { id: 'binary', option: 'Binary (Yes/No)' }
  ];

  // Map UI column types to API column types
  const mapColumnTypeToApi = (uiType) => {
    if (uiType === 'summary') return 'text';
    if (uiType === 'binary') return 'binary';
    return 'value'; // Default for 'value' type
  };

  const handleAddColumn = async () => {
    try {
      setIsAddingColumn(true);
      
      // Call the API to get column instructions
      const apiColumnType = mapColumnTypeToApi(newColumnType);
      const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/get_column_instructions`, {
      // const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/mock_get_column_instructions`, {
        column_type: apiColumnType,
        field_instructions: newColumnInstruction
      });
      
      // Store the response data
      const instructionsData = response.data;
      
      // Create the new column definition
      const newColumn = {
        Header: newColumnName,
        accessor: `custom_${Date.now()}`, // Use timestamp to ensure unique accessor
        type: apiColumnType,
        field: instructionsData.field,
        instructions: instructionsData.instructions,
        fieldInstructions: newColumnInstruction
      };
      
      // Add to custom columns
      setCustomColumns(prev => [...prev, newColumn]);
      
      // Reset form fields
      setNewColumnName('');
      setNewColumnType(null);
      setNewColumnInstruction('');
      setIsAddColumnOpen(false);
    } catch (error) {
      console.error('Error getting column instructions:', error);
      toast({
        title: "Error adding column",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsAddingColumn(false);
    }
  };

  return (
    <Box>
      <Container className='dashboard-sheet-container' maxW="1920px" pt={8}>
        <VStack spacing={8} align="stretch">
          <HStack justify="space-between" align="center">
          <Heading as="h1" size="xl">New Dashboard</Heading>
          <Button variant={'default'} isDisabled={!dashboardData}>Save Dashboard</Button>
          </HStack>

          <Card px={5} py={4} borderRadius="md" shadow={'none'}>

              
          {(filtersApplied && !isExpanded) && (
                <Flex justify="space-between" align="center" mb={isExpanded ? 4 : 0}>
                  <HStack>
                    <TriageIcon width={24} height={24} fill="#000" />
                    <Text fontSize={'lg'} fontFamily={'Garnett'} fontWeight={'600'} color="#000">{query}</Text>
                  </HStack>
                  <HStack>
                    <Button variant={'default_ghost'}>Show Filters</Button>
                    
                    {/* Replace the Add Column button with a Popover */}
                    <Popover
                      isOpen={isAddColumnOpen}
                      onOpen={() => setIsAddColumnOpen(true)}
                      onClose={() => setIsAddColumnOpen(false)}
                      placement="bottom-end"
                      closeOnBlur={false}
                      closeOnEsc={false}
                      arrowSize={20}
                    >
                      <PopoverTrigger>
                        <Button 
                          variant={'default_muted'} 
                          leftIcon={<AddColumnIcon fill="#000" height="24px" width="24px" />}
                        >
                          Add Column
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent p={5} width="350px">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader fontWeight="bold">Add New Column</PopoverHeader>
                        <PopoverBody>
                          <VStack spacing={4} align="stretch">
                            <FormControl isRequired>
                              <FormLabel>Column Name</FormLabel>
                              <Input 
                                variant="default"
                                placeholder="Enter column name"
                                value={newColumnName}
                                onChange={(e) => setNewColumnName(e.target.value)}
                              />
                            </FormControl>
                            
                            <FormControl isRequired>
                              <FormLabel>Column Type</FormLabel>
                              <SelectDropdown
                                options={columnTypeOptions}
                                onChange={(selectedValue) => setNewColumnType(selectedValue)}
                                selectedValue={columnTypeOptions.find(opt => opt.id === newColumnType)}
                                placeholderText="Select column type"
                                isMultiSelect={false}
                                shouldShowAssignee={false}
                                shouldShowSearch={true}
                                isClearable={false}
                              />
                            </FormControl>
                            
                            <FormControl isRequired>
                              <FormLabel>Field Instruction</FormLabel>
                              <Textarea
                                variant="default"
                                placeholder="Enter field description or instructions"
                                value={newColumnInstruction}
                                onChange={(e) => setNewColumnInstruction(e.target.value)}
                                size="md"
                                resize="vertical"
                              />
                            </FormControl>
                          </VStack>
                        </PopoverBody>
                        <PopoverFooter display="flex" justifyContent="flex-end">
                          <Button 
                            variant="default" 
                            onClick={handleAddColumn}
                            isDisabled={!newColumnName || !newColumnType || !newColumnInstruction || isAddingColumn}
                            isLoading={isAddingColumn}
                          >
                            Save
                          </Button>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  </HStack>
                </Flex>
              )}

            <Container maxW={'container.lg'}>

            <Box onClick={() => filtersApplied && setIsExpanded(!isExpanded)} 
                 cursor={filtersApplied ? "pointer" : "default"}>
              
              <Collapse in={!filtersApplied || isExpanded}>
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <FormControl>
                      <FormLabel fontFamily={'Inter'} fontSize={'16px'} fontWeight={'500'}>
                        What would you like to see in your dashboard?
                      </FormLabel>
                      <HStack className='search-input-container'>
                        <Input
                          className='search-input'
                          variant={'default'}
                          placeholder="Describe the criteria for your dashboard"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                        <IconButton
                          className='search-submit-button'
                          icon={<SendIcon />}
                          variant={'default'}
                          onClick={handleSearch}
                          isDisabled={!query.trim()}
                          isLoading={isLoading}
                        >
                          Submit
                        </IconButton>
                      </HStack>
                      
                      {/* Suggested query pills - only shown before submission */}
                      {showSuggestions && (
                        <Box mt={6}>
                          <Text fontSize="sm" mb={3} color="gray.600">Suggestions</Text>
                          <Flex flexWrap="wrap" gap={2}>
                            {[
                              "Disputes presented in front of high court or above",
                              "Commercial disputes in arbitration",
                              "Disputes from vendors alleging non payment"
                            ].map((suggestion, index) => (
                              <Button 
                                key={index}
                                size="sm" 
                                variant="suggestion_button" 
                                borderRadius="full"
                                onClick={() => {
                                  setQuery(suggestion);
                                }}
                              >
                                {suggestion}
                              </Button>
                            ))}
                          </Flex>
                        </Box>
                      )}
                    </FormControl>
                    
                    {dashboardConfig && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Divider my={6} />
                        <FilterVerificationStep />
                      </motion.div>
                    )}
                  </motion.div>
                </AnimatePresence>
              </Collapse>
            </Box>
            </Container>
          </Card>
        </VStack>
      </Container>

      {dashboardData && dashboardData.dashboard.results && dashboardData.dashboard.results.length > 0 && (
        <Box className='dashboard-sheet-container' position="relative">
          <DashboardSheet
            data={dashboardData.dashboard.results}
            columns={columns}
            visibleColumnAccessors={visibleColumnAccessors}
            customColumns={customColumns}
          />
        </Box>
      )}
    </Box>
  );
};

export default NewDashboard;
