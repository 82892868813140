import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactFlow, {
    useNodesState,
    useEdgesState,
    Background,
    ReactFlowProvider,
    Panel,
    useReactFlow
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Box, Heading, Text, Card, VStack, Container, Button, Flex, Icon, Input, List, ListItem, Divider, HStack, useToast, Spinner } from '@chakra-ui/react';
import { FaFileUpload, FaFile, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

import PleadingBlockNode from './PleadingBlockNode.jsx';
import InstructionNode from './InstructionNode.jsx';
import ExcerptNode from './ExcerptNode.jsx';

import { ReactComponent as CloseIcon } from '../../images/close.svg'
import { ReactComponent as AddIcon } from '../../images/add.svg'
// Define node types for React Flow
const nodeTypes = {
    pleadingBlock: PleadingBlockNode,
    instruction: InstructionNode,
    excerpt: ExcerptNode
};

// Create a motion version of Card component
const MotionCard = motion(Card);

const DraftRespondentPleading = () => {
    const { analysisID, caseID } = useParams();
    const [claimantPleadingBlocks, setClaimantPleadingBlocks] = useState(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [unprocessedDocuments, setUnprocessedDocuments] = useState([]);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [processingFiles, setProcessingFiles] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const [nodePositions, setNodePositions] = useState({});
    const [isGenerating, setIsGenerating] = useState(false)

    useEffect(() => {
        fetchClaimantPleadingBlocks();
    }, []);

    useEffect(() => {
        if (claimantPleadingBlocks) {
            createNodes();
        }
    }, [claimantPleadingBlocks]);

    const fetchClaimantPleadingBlocks = async () => {
        setLoading(true);
        try {
            // const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/mock_claimant_pleading_as_blocks`;
            const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/claimant_pleading_as_blocks`;
            const response = await axios.get(url, {
                params: {
                    "analysis_id": analysisID
                }
            });
            setClaimantPleadingBlocks(response.data);
        } catch (error) {
            console.error('Error fetching claimant pleading blocks:', error);
        } finally {
            setLoading(false);
        }
    }

    const onAddInstruction = useCallback((nodeId) => {
        console.log("ON ADD INSTRUCTION CLICKED", nodeId)
        // Use the latest nodes state directly from the setter function
        setNodes(currentNodes => {
            // Find the source node from current nodes
            const sourceNode = currentNodes.find(node => node.id === nodeId);
            console.log("SOURCE NODE ", sourceNode)
            if (!sourceNode) return currentNodes; // Return unchanged if source not found

            // Create a unique ID for the new instruction node
            const newNodeId = `instruction-${Date.now()}`;

            // Create the new instruction node to the right of the source node
            const newNode = {
                id: newNodeId,
                type: 'instruction',
                position: {
                    x: sourceNode.position.x + 600, // Position it to the right
                    y: sourceNode.position.y       // Same vertical position
                },
                data: {
                    instruction: '',
                    sourceNodeId: sourceNode.id, // Store the source node ID for reference
                    // Store the source node data directly in the instruction node
                    sourceNodeData: {
                        id: sourceNode.id,
                        title: sourceNode.data.title,
                        summary: sourceNode.data.summary,
                        sentences: sourceNode.data.sentences
                    },
                    onSave: async (instruction) => {
                        // Access the source node data directly from the node's data
                        // instead of trying to find it in the current nodes array

                        // Log both the instruction and the connected pleading block data
                        console.log("Instruction saved:", instruction);
                        console.log("Connected pleading block data:", newNode.data.sourceNodeData);

                        setNodes(nds =>
                            nds.map(node =>
                                node.id === newNodeId
                                    ? { ...node, data: { ...node.data, instruction } }
                                    : node
                            )
                        );

                        // Call API to get defense blocks
                        try {
                            setIsLoading(true);
                            const response = await axios.post(
                                // `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/mock_get_nodes_from_instruction`,
                                `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/get_nodes_from_instruction`,
                                {
                                    case_id: caseID,
                                    analysis_id: analysisID,
                                    instruction: instruction,
                                    claimant_block: newNode.data.sourceNodeData
                                }
                            );

                            if (response.data.success && response.data.blocks) {
                                // Create excerpt nodes for each block returned
                                const excerptNodes = response.data.blocks.map((block, index) => {
                                    const excerptNodeId = `excerpt-${Date.now()}-${index}`;
                                    return {
                                        id: excerptNodeId,
                                        type: 'excerpt',
                                        position: {
                                            x: sourceNode.position.x + 1200, // Position to the right of instruction
                                            y: sourceNode.position.y + (index * 250) // Spacing between excerpt nodes
                                        },
                                        data: {
                                            title: block.title,
                                            sentences: block.sentences,
                                            summary: block.summary,
                                            instructionNodeId: newNodeId
                                        }
                                    };
                                });

                                // Add the new excerpt nodes
                                setNodes(currentNodes => [...currentNodes, ...excerptNodes]);

                                // Add edges connecting instruction node to each excerpt node
                                excerptNodes.forEach(excerptNode => {
                                    setEdges(currentEdges => [
                                        ...currentEdges,
                                        {
                                            id: `e-${newNodeId}-${excerptNode.id}`,
                                            source: newNodeId,
                                            target: excerptNode.id,
                                        }
                                    ]);
                                });

                                toast({
                                    title: "Defense blocks generated",
                                    description: `${excerptNodes.length} supporting blocks created`,
                                    status: "success",
                                    duration: 5000,
                                    isClosable: true,
                                });
                            }
                        } catch (error) {
                            console.error('Error generating defense blocks:', error);
                            toast({
                                title: "Error generating defense blocks",
                                description: error.response?.data?.error || "An unexpected error occurred",
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            });
                        } finally {
                            setIsLoading(false);
                        }
                    },
                    onNodeResize: (nodeId, height) => handleNodeResize(nodeId, height)
                },
            };

            // Add the new node
            const updatedNodes = [...currentNodes, newNode];

            // Add the edge in a separate operation
            setEdges(currentEdges => [
                ...currentEdges,
                {
                    id: `e-${sourceNode.id}-${newNodeId}`,
                    source: sourceNode.id,
                    target: newNodeId,
                }
            ]);

            return updatedNodes;
        });
    }, [setNodes, setEdges, nodes, caseID, analysisID, toast]);

    const onNodeDrag = useCallback((event, node) => {
        // Only respond to instruction node drag events
        if (node.id.startsWith('instruction-')) {
            setNodes(currentNodes => {
                // Find all excerpt nodes connected to this instruction node
                const relatedExcerpts = currentNodes.filter(n =>
                    n.id.startsWith('excerpt-') &&
                    n.data.instructionNodeId === node.id
                );

                if (relatedExcerpts.length === 0) return currentNodes;

                // Calculate positions for all related excerpt nodes
                const totalExcerpts = relatedExcerpts.length;
                const verticalSpacing = 250;

                // Update all nodes, modifying only the related excerpt nodes
                return currentNodes.map(n => {
                    if (n.id.startsWith('excerpt-') && n.data.instructionNodeId === node.id) {
                        const index = relatedExcerpts.findIndex(e => e.id === n.id);
                        const startY = node.position.y - ((totalExcerpts - 1) * verticalSpacing / 2);
                        const yPosition = startY + (index * verticalSpacing);

                        return {
                            ...n,
                            position: {
                                x: node.position.x + 600, // Position to the right of instruction
                                y: yPosition
                            }
                        };
                    }
                    return n;
                });
            });
        }
    }, [setNodes]);


    const createNodes = () => {
        if (!claimantPleadingBlocks || !claimantPleadingBlocks.blocks) return;

        // Initially position nodes with a default spacing
        const blockNodes = claimantPleadingBlocks.blocks.map((block, index) => ({
            id: `block-${index}`,
            type: 'pleadingBlock',
            position: { x: 100, y: index * 300 }, // Initial placement
            data: {
                ...block,
                index: index + 1,
                id: `block-${index}`,
                onAddInstruction,
                onNodeResize: (nodeId, height) => handleNodeResize(nodeId, height)
            },
        }));

        setNodes(blockNodes);
    };

    const handleNodeResize = useCallback((nodeId, height) => {
        // Update the stored height for this node
        setNodePositions(prev => ({
            ...prev,
            [nodeId]: { height }
        }));
    }, []);

    // Adjust node positions based on the actual heights
    useEffect(() => {
        if (Object.keys(nodePositions).length === 0 || nodes.length === 0) return;

        // Group nodes by type
        const pleadingNodes = nodes.filter(node => node.id.startsWith('block-'));
        const instructionNodes = nodes.filter(node => node.id.startsWith('instruction-'));
        const excerptNodes = nodes.filter(node => node.id.startsWith('excerpt-'));

        // Sort pleading nodes by index
        const sortedPleadingNodes = [...pleadingNodes].sort((a, b) => {
            const aIndex = parseInt(a.id.split('-')[1]);
            const bIndex = parseInt(b.id.split('-')[1]);
            return aIndex - bIndex;
        });

        // Position pleading nodes vertically with spacing
        let currentY = 50;
        const updatedPleadingNodes = sortedPleadingNodes.map(node => {
            const newNode = { ...node, position: { ...node.position, y: currentY } };
            const nodeHeight = nodePositions[node.id]?.height || 250;
            const spacing = 150;
            currentY += nodeHeight + spacing;
            return newNode;
        });

        // Update instruction nodes to match their source node's Y position
        const updatedInstructionNodes = instructionNodes.map(node => {
            const sourceNodeId = node.data.sourceNodeId;
            const sourceNode = updatedPleadingNodes.find(n => n.id === sourceNodeId);

            if (sourceNode) {
                return {
                    ...node,
                    position: {
                        ...node.position,
                        x: sourceNode.position.x + 600,
                        y: sourceNode.position.y // Align with source node
                    }
                };
            }
            return node;
        });

        // Position excerpt nodes to the right of their instruction node
        const updatedExcerptNodes = excerptNodes.map(node => {
            const instructionNodeId = node.data.instructionNodeId;
            const instructionNode = updatedInstructionNodes.find(n => n.id === instructionNodeId);

            if (instructionNode) {
                // Find all excerpts connected to this instruction
                const relatedExcerpts = excerptNodes.filter(e =>
                    e.data.instructionNodeId === instructionNodeId
                );
                const totalExcerpts = relatedExcerpts.length;

                // Get index of current excerpt in the group
                const index = relatedExcerpts.findIndex(e => e.id === node.id);

                // Calculate vertical position with even spacing
                const verticalSpacing = 250; // Spacing between excerpt nodes
                const startY = instructionNode.position.y - ((totalExcerpts - 1) * verticalSpacing / 2);
                const yPosition = startY + (index * verticalSpacing);

                return {
                    ...node,
                    position: {
                        x: instructionNode.position.x + 600, // Position to the right of instruction
                        y: yPosition // Evenly space vertically
                    }
                };
            }
            return node;
        });

        setNodes(updatedPleadingNodes.concat(updatedInstructionNodes, updatedExcerptNodes));
    }, [nodePositions, setNodes]);

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);

        // Filter for only PDF and DOCX files
        const validFiles = files.filter(file => {
            const isValid = file.type === 'application/pdf' ||
                file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

            if (!isValid) {
                toast({
                    title: "Invalid file type",
                    description: `${file.name} is not a PDF or DOCX file`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }

            return isValid;
        });

        setUnprocessedDocuments(prev => [...prev, ...validFiles]);
    };

    const removeDocument = (index, isProcessed) => {
        if (isProcessed) {
            setDocuments(prev => prev.filter((_, i) => i !== index));
        } else {
            setUnprocessedDocuments(prev => prev.filter((_, i) => i !== index));
        }
    };

    const processDocuments = async () => {
        setProcessingFiles(true);

        try {
            // Create a single FormData object for all files
            const formData = new FormData();

            // Append all files to the formData with the 'files[]' field name
            unprocessedDocuments.forEach(file => {
                formData.append('files[]', file);
            });

            // Make a single API call to process all files at once
            const response = await axios.post(
                `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/process_supporting_docs`,
                formData,
                {
                    params: {
                        case_id: caseID,
                        analysis_id: analysisID
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            // Process the response and update state
            if (response.data.success) {
                // Convert the processed files into the format expected by our state
                const processedFiles = unprocessedDocuments.map((file, index) => ({
                    ...file,
                    processedData: response.data.results[index]
                }));

                // Update documents state with successfully processed files
                setDocuments(prev => [...prev, ...processedFiles]);

                // Clear unprocessed documents since all were processed
                setUnprocessedDocuments([]);

                toast({
                    title: "Files processed successfully",
                    description: `${response.data.processed_files} file(s) processed successfully`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error processing documents:', error);

            // Keep the files in unprocessed state
            toast({
                title: "Error processing files",
                description: error.response?.data?.error || "An unexpected error occurred",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setProcessingFiles(false);
        }
    };

    const handleGeneratePleading = useCallback(async () => {
        try {
            setIsGenerating(true);
            
            // Group nodes by type
            const pleadingNodes = nodes.filter(node => node.id.startsWith('block-'));
            const instructionNodes = nodes.filter(node => node.id.startsWith('instruction-'));
            const excerptNodes = nodes.filter(node => node.id.startsWith('excerpt-'));
            
            // Create a structure that preserves the relationships
            const pleadingStructure = {
                claimantBlocks: pleadingNodes.map(node => ({
                    id: node.id,
                    title: node.data.title,
                    summary: node.data.summary,
                    sentences: node.data.sentences,
                    instructions: instructionNodes
                        .filter(instruction => instruction.data.sourceNodeId === node.id)
                        .map(instruction => ({
                            id: instruction.id,
                            instruction: instruction.data.instruction,
                            supportingExcerpts: excerptNodes
                                .filter(excerpt => excerpt.data.instructionNodeId === instruction.id)
                                .map(excerpt => ({
                                    id: excerpt.id,
                                    title: excerpt.data.title,
                                    summary: excerpt.data.summary,
                                    sentences: excerpt.data.sentences
                                }))
                        }))
                }))
            };
            
            console.log("Sending pleading structure to API:", pleadingStructure);
            
            // Make API call to generate the pleading document
            const response = await axios.post(
                `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/generate_respondent_pleading`,
                pleadingStructure,
                {
                    responseType: 'blob',
                    params: {
                        case_id: caseID,
                        analysis_id: analysisID
                    }
                }
            );
            
            // Create a download link for the received file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'respondent_pleading.docx');
            document.body.appendChild(link);
            link.click();
            link.remove();
            
            toast({
                title: "Pleading generated successfully",
                description: "Your document has been downloaded",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            
        } catch (error) {
            console.error('Error generating pleading:', error);
            toast({
                title: "Error generating pleading",
                description: error.response?.data?.error || "An unexpected error occurred",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsGenerating(false);
        }
    }, [nodes, caseID, analysisID, toast]);

    return (
        <Box mr={5}>
            <Box h="calc(100vh - 190px)" border="1px solid #E2E8F0" borderRadius="md">
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" h="100%">
                        <Text>Loading pleading blocks...</Text>
                    </Box>
                ) : (
                    <ReactFlowProvider>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            nodeTypes={nodeTypes}
                            fitView
                            minZoom={0.2}
                            maxZoom={1.5}
                            defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
                            proOptions={{ hideAttribution: true }}
                            onNodeDrag={onNodeDrag}
                            defaultEdgeOptions={{
                                type: 'smoothstep',
                                style: {
                                    strokeWidth: 3,
                                    stroke: '#00B870',
                                },
                            }}
                        >
                            <Background color="#ccc" size={2} gap={30} style={{ backgroundColor: '#f5f5f5' }} />

                            <Panel position="top-right">
                                <VStack align="start" spacing={3}>
                                    {!isPanelOpen &&
                                        <Button
                                            leftIcon={<Icon as={FaFileUpload} />}
                                            variant={'default_muted'}
                                            onClick={() => setIsPanelOpen(true)}
                                            mb={isPanelOpen ? 2 : 0}
                                        >
                                            {documents.length > 0
                                                ? `${documents.length} document${documents.length > 1 ? 's' : ''} uploaded`
                                                : 'Add Supporting Documents'}
                                        </Button>
                                    }

                                    <AnimatePresence>
                                        {isPanelOpen && (
                                            <MotionCard
                                                p={4}
                                                width="350px"
                                                boxShadow="md"
                                                bg="white"
                                                initial={{ opacity: 0, x: 50, scale: 0.95 }}
                                                animate={{ opacity: 1, x: 0, scale: 1 }}
                                                exit={{ opacity: 0, x: 50, scale: 0.95 }}
                                                transition={{
                                                    duration: 0.3,
                                                    ease: "easeInOut"
                                                }}
                                            >
                                                <VStack align="stretch" spacing={4}>
                                                    <HStack justify={'space-between'}>
                                                        <Heading size="sm">Supporting Documents</Heading>
                                                        <Button variant={'ghost'} onClick={() => setIsPanelOpen(false)}>
                                                            <CloseIcon fill='#a8a8a8' style={{ 'cursor': 'pointer' }} />
                                                        </Button>
                                                    </HStack>
                                                    <Divider />

                                                    {documents.length === 0 && unprocessedDocuments.length === 0 ? (
                                                        <VStack py={4} align="center">
                                                            <Icon as={FaFileUpload} boxSize={10} color="gray.400" />
                                                            <Text color="gray.500" textAlign="center">
                                                                No documents uploaded
                                                            </Text>
                                                        </VStack>
                                                    ) : (
                                                        <List spacing={2} width="100%">
                                                            {documents.length > 0 && (
                                                                <Box>
                                                                    <Text fontWeight="bold" fontSize={'0.8rem'} mb={2} textTransform={'uppercase'}>Processed Documents</Text>
                                                                    {documents.map((doc, index) => (
                                                                        <ListItem key={`processed-${index}`}>
                                                                            <Flex justifyContent="space-between" alignItems="center" bg="green.50" p={2} borderRadius="md">
                                                                                <Flex alignItems="center">
                                                                                    <Icon as={FaFile} mr={2} color="green.500" />
                                                                                    <Text fontSize="sm" isTruncated maxWidth="230px">
                                                                                        {doc.name}
                                                                                    </Text>
                                                                                </Flex>
                                                                                <Button
                                                                                    size="xs"
                                                                                    colorScheme="red"
                                                                                    variant="ghost"
                                                                                    onClick={() => removeDocument(index, true)}
                                                                                >
                                                                                    <Icon as={FaTimes} />
                                                                                </Button>
                                                                            </Flex>
                                                                        </ListItem>
                                                                    ))}
                                                                </Box>
                                                            )}

                                                            {unprocessedDocuments.length > 0 && (
                                                                <Box mt={4}>
                                                                    <Divider mt={4} />
                                                                    <Text fontWeight="bold" fontSize={'0.8rem'} mb={2} textTransform={'uppercase'} mt={documents.length > 0 ? 4 : 2} mb={1}>Pending Documents</Text>
                                                                    {unprocessedDocuments.map((doc, index) => (
                                                                        <ListItem key={`unprocessed-${index}`}>
                                                                            <Flex justifyContent="space-between" alignItems="center" bg="gray.50" p={2} borderRadius="md">
                                                                                <Flex alignItems="center">
                                                                                    <Icon as={FaFile} mr={2} color="gray.500" />
                                                                                    <Text fontSize="sm" isTruncated maxWidth="230px">
                                                                                        {doc.name}
                                                                                    </Text>
                                                                                </Flex>
                                                                                <Button
                                                                                    size="xs"
                                                                                    colorScheme="red"
                                                                                    variant="ghost"
                                                                                    onClick={() => removeDocument(index, false)}
                                                                                >
                                                                                    <Icon as={FaTimes} />
                                                                                </Button>
                                                                            </Flex>
                                                                        </ListItem>
                                                                    ))}
                                                                </Box>
                                                            )}
                                                        </List>
                                                    )}

                                                    <Box>
                                                        <VStack>
                                                            <input
                                                                type="file"
                                                                multiple
                                                                id="file-upload"
                                                                style={{ display: 'none' }}
                                                                onChange={handleFileUpload}
                                                                accept=".pdf,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            />

                                                            <label htmlFor="file-upload" style={{ width: '100%' }}>
                                                                <Button
                                                                    as="span"
                                                                    variant={'default_muted'}
                                                                    size="sm"
                                                                    cursor="pointer"
                                                                    width="100%"
                                                                    leftIcon={<AddIcon fill='#000' height={'18px'} width={'18px'} />}
                                                                    isDisabled={processingFiles}
                                                                >
                                                                    Select Files
                                                                </Button>
                                                            </label>
                                                            <Button
                                                                variant={'default'}
                                                                size="sm"
                                                                width="100%"
                                                                isDisabled={unprocessedDocuments.length === 0 || processingFiles}
                                                                onClick={processDocuments}
                                                            >
                                                                {processingFiles ? (
                                                                    <Flex align="center">
                                                                        <Spinner size="sm" mr={2} />
                                                                        Processing...
                                                                    </Flex>
                                                                ) : (
                                                                    'Process Files'
                                                                )}
                                                            </Button>
                                                        </VStack>
                                                    </Box>
                                                </VStack>
                                            </MotionCard>
                                        )}
                                    </AnimatePresence>
                                </VStack>
                            </Panel>

                            <Panel position='bottom-right'>
                                <Button
                                    variant={'default'}
                                    onClick={handleGeneratePleading}
                                    isLoading={isGenerating}
                                    // isDisabled={documents.length == 0}
                                >
                                    Generate Pleading
                                </Button>
                            </Panel>
                        </ReactFlow>
                    </ReactFlowProvider>
                )}
            </Box>
        </Box>
    );
}

export default DraftRespondentPleading;