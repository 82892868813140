/**
 * DashboardSheet Component
 * 
 * A reusable dashboard component that displays data in a sortable, resizable table with drag-and-drop functionality.
 * 
 * @param {Object} props
 * @param {Array} props.data - Array of objects containing the data to display
 * @param {Array} props.columns - Array of column configuration objects
 * @param {Array} props.visibleColumnAccessors - Array of column accessor strings to determine which columns to display
 * @param {Function} props.getRiskColor - Function to determine color scheme for risk badges
 */

import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTable, useResizeColumns, useBlockLayout, useSortBy } from 'react-table';
import { 
    Container,
    Box, 
    Badge, 
    Button, 
    HStack, 
    Text
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import DashboardReferencesModal from '../DashboardReferencesModal';

import { ReactComponent as DocIcon } from '../../images/doc.svg';
import './index.scss';

const FIELDS_TO_COLLAPSE = ['nature_of_action', 'category', 'damages', 'relief_sought'];

const DashboardSheet = ({
    data = [],
    columns = [],
    visibleColumnAccessors = [],
    customColumns = []
}) => {
    const navigate = useNavigate();
    const [customColumnData, setCustomColumnData] = useState({});
    const [expandedCells, setExpandedCells] = useState({});
    const [hoveredCell, setHoveredCell] = useState(null);
    const [isLoadingReferences, setIsLoadingReferences] = useState(false);
    const [isReferencesModalOpen, setIsReferencesModalOpen] = useState(false);
    const [references, setReferences] = useState([]);
    
    // Add refs for scrolling to last column and tracking previous columns
    const tableRef = useRef(null);
    const prevCustomColumnsRef = useRef([]);

    // Update useEffect to react to customColumns prop changes
    useEffect(() => {
        if (customColumns.length > prevCustomColumnsRef.current.length) {
            // A new column was added
            setTimeout(() => {
                scrollToLastColumn();
            }, 100); // Small delay to ensure column is rendered
        }
        
        // Update ref with current columns for next comparison
        prevCustomColumnsRef.current = customColumns;
        
        if (customColumns.length > 0) {
            // For any new custom columns, initialize data and fetch values
            customColumns.forEach(column => {
                // Check if we already have data for this column
                const columnDataExists = Object.keys(customColumnData).some(key => 
                    key.endsWith(`_${column.accessor}`)
                );
                
                if (!columnDataExists) {
                    // Initialize with loading state for all rows
                    const initialData = {};
                    data.forEach(row => {
                        initialData[`${row.case_id}_${column.accessor}`] = { isLoading: true, value: "Loading..." };
                    });
                    
                    setCustomColumnData(prev => ({...prev, ...initialData}));
                    
                    // Fetch actual data for each row
                    data.forEach(row => {
                        fetchCustomColumnValue(row.case_id, column);
                    });
                }
            });
        }
    }, [customColumns, data]);

    // Keep the fetchCustomColumnValue function as is
    const fetchCustomColumnValue = async (caseId, column) => {
        try {
            // Extract the appropriate data based on column type and field
            console.log('column', column);
            let extractionSentences = [];
            
            if (column.type === 'text' && column.instructions.extraction_sentences) {
                extractionSentences = column.instructions.extraction_sentences;
            } else if (column.type === 'value' && column.instructions.extraction_sentences) {
                extractionSentences = column.instructions.extraction_sentences;
            } else if (column.type === 'binary' && column.instructions.extraction_sentences) {
                // Combine yes and no examples
                extractionSentences = [
                    ...(column.instructions.extraction_sentences.yes || []),
                    ...(column.instructions.extraction_sentences.no || [])
                ];
            }
            
            const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/get_custom_column_value`, {
            // const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/mock_get_custom_column_value`, {
                case_id: caseId,
                column_type: column.type,
                extraction_sentences: extractionSentences,
                field_instructions: column.fieldInstructions
            });

            setReferences({
                citations: response?.data?.citations || null,
                case_id: caseId
            });
            
            // Update the custom column data
            setCustomColumnData(prev => ({
                ...prev,
                [`${caseId}_${column.accessor}`]: { 
                    isLoading: false,
                    value: response.data.value,
                    type: response.data.type,
                    citations: response?.data?.citations || null // Store citations data if available
                }
            }));
        } catch (error) {
            console.error(`Error fetching custom column value for case ${caseId}:`, error);
            // Update with error state
            setCustomColumnData(prev => ({
                ...prev,
                [`${caseId}_${column.accessor}`]: { 
                    isLoading: false,
                    value: "Error loading data",
                    error: true
                }
            }));
        }
    };

    // Function to scroll to the last column
    const scrollToLastColumn = () => {
        if (tableRef.current) {
            tableRef.current.scrollLeft = tableRef.current.scrollWidth;
        }
    };

    // Calculate max widths based on content length
    const getMaxWidth = (accessor) => {
        // Set consistent padding for all columns
        const HORIZONTAL_PADDING = 24; // 12px padding on each side
        const MAX_COLUMN_WIDTH = 300; // Maximum width for any column
        
        if (FIELDS_TO_COLLAPSE.includes(accessor)) {
            // For collapsed fields with multiple badges/items
            const baseWidth = 150; // Minimum width for these fields
            
            // Get the maximum content width needed
            const contentWidth = Math.max(
                ...data.map(row => {
                    if (typeof row[accessor] === 'object' && row[accessor]) {
                        // Count items and estimate their rendered width
                        const entries = Object.entries(row[accessor]);
                        
                        // If no entries, return minimum width
                        if (entries.length === 0) return baseWidth;
                        
                        // For each key-value pair, estimate display width
                        // Each entry renders as "key → value1, value2"
                        return entries.reduce((maxEntryWidth, [key, values]) => {
                            const valueText = values && values.length > 0 ? ` → ${values.join(', ')}` : '';
                            const entryText = `${key}${valueText}`;
                            // Estimate 8px per character + 12px for margins between entries
                            const entryWidth = entryText.length * 8 + 12;
                            return Math.max(maxEntryWidth, entryWidth);
                        }, 0);
                    }
                    return baseWidth;
                })
            );
            
            return Math.min(MAX_COLUMN_WIDTH, Math.max(baseWidth, contentWidth + HORIZONTAL_PADDING));
        }
        
        // For case_name special field with badge and icon
        if (accessor === 'case_name') {
            const minWidth = 240; // Provide more space for case names as they're important
            const contentWidth = Math.max(
                ...data.map(row => {
                    const value = row[accessor] || '';
                    const valueStr = String(value);
                    // Account for icon (16px) + character width + badge padding
                    return 16 + (valueStr.length * 8) + 16;
                })
            );
            
            return Math.min(MAX_COLUMN_WIDTH, Math.max(minWidth, contentWidth + HORIZONTAL_PADDING));
        }
        
        // For regular text fields
        const headerWidth = accessor.length * 9; // Space for header text
        const minWidth = Math.max(100, headerWidth); // At least 100px or header width
        
        const contentWidth = Math.max(
            ...data.map(row => {
                const value = row[accessor] || '';
                const valueStr = String(value);
                // Standard character width estimation (approximately 8px per character)
                return valueStr.length * 8;
            })
        );
        
        return Math.min(MAX_COLUMN_WIDTH, Math.max(minWidth, contentWidth + HORIZONTAL_PADDING));
    };

    // Modify the visibleColumns useMemo to use the customColumns prop
    const visibleColumns = useMemo(() => {
        // Apply width calculations to the columns
        const baseColumns = columns
            .filter(column => 
                visibleColumnAccessors.includes(column.accessor) && 
                column.accessor !== 'case_id'
            )
            .map(column => {
                const calculatedWidth = getMaxWidth(column.accessor);
                return {
                    ...column,
                    width: column.width || calculatedWidth,
                    minWidth: column.minWidth || calculatedWidth
                };
            });
            
        // Add custom columns
        const allColumns = [
            ...baseColumns,
            ...customColumns.map(column => ({
                ...column,
                width: 200, // Default width for custom columns
                minWidth: 150,
                Cell: ({ row }) => {
                    const data = customColumnData[`${row.original.case_id}_${column.accessor}`];
                    if (!data) return null;
                    
                    if (data.isLoading) {
                        return <Text color="gray.500">Loading...</Text>;
                    }
                    
                    if (data.error) {
                        return <Text color="red.500">{data.value}</Text>;
                    }
                    
                    // Render based on column type
                    if (column.type === 'binary') {
                        const value = data.value.toLowerCase();
                        return <Badge colorScheme={value === 'yes' ? 'green' : 'gray'}>{value}</Badge>;
                    }
                    
                    return <Text>{data.value}</Text>;
                }
            }))
        ];
        
        return allColumns;
    }, [columns, visibleColumnAccessors, data, customColumns, customColumnData]);

    const [records, setRecords] = useState(data);

    const DraggableRow = ({ index, moveRow, ...props }) => {
        const dropRef = React.useRef(null);
        const dragRef = React.useRef(null);

        const [, drop] = useDrop({
            accept: 'row',
            hover(item, monitor) {
                if (!dropRef.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }
                moveRow(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        });

        const [{ isDragging }, drag, preview] = useDrag({
            type: 'row',
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        preview(drop(dropRef));
        drag(dragRef);

        return (
            <tr ref={dropRef} style={{ opacity: isDragging ? 0.5 : 1 }} {...props}>
                <td ref={dragRef} style={{ cursor: 'move' }}>⋮</td>
                {props.children}
            </tr>
        );
    };

    const moveRow = useCallback((dragIndex, hoverIndex) => {
        setRecords((prevRecords) => {
            const newRecords = [...prevRecords];
            const [reorderedItem] = newRecords.splice(dragIndex, 1);
            newRecords.splice(hoverIndex, 0, reorderedItem);
            return newRecords;
        });
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns: visibleColumns,
            data: records,
        },
        useBlockLayout,
        useResizeColumns,
        useSortBy
    );

    // Function to toggle cell expansion
    const toggleCellExpansion = (rowId, columnId) => {
        const cellKey = `${rowId}_${columnId}`;
        setExpandedCells(prev => ({
            ...prev,
            [cellKey]: !prev[cellKey]
        }));
    };

    // Function to check if a cell is expanded
    const isCellExpanded = (rowId, columnId) => {
        const cellKey = `${rowId}_${columnId}`;
        return expandedCells[cellKey] || false;
    };

    // Function to handle showing references for a cell
    const handleOpenReferencesModal = (rowId, columnId) => {
        const cellKey = `${rowId}_${columnId}`;
        const cellData = customColumnData[cellKey];
        
        if (cellData && cellData.citations) {
            // Set the references data and open the modal directly
            setReferences({
                text: cellData.citations.text,
                references: cellData.citations.references,
                case_id: rowId
            });
            setIsReferencesModalOpen(true);
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Container className='dashboard-sheet-container' maxW="1920px">
                <Box 
                    className="table" 
                    style={{ overflowX: 'auto' }} 
                    {...getTableProps()}
                    ref={tableRef} // Add the ref here to enable scrolling
                >
                    <div>
                        {headerGroups.map(headerGroup => (
                            <div {...headerGroup.getHeaderGroupProps()} className="tr">
                                {headerGroup.headers.map(column => (
                                    <div
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="th"
                                        style={{
                                            ...column.getHeaderProps().style,
                                            position: column.sticky ? 'sticky' : 'relative',
                                            left: column.sticky ? 0 : 'auto',
                                            background: 'white',
                                            zIndex: column.sticky ? 1 : 0
                                        }}
                                    >
                                        {column.render('Header')}
                                        <div
                                            {...column.getResizerProps()}
                                            className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div {...getTableBodyProps()}>
                        {rows.map((row, index) => {
                            prepareRow(row);
                            return (
                                <DraggableRow index={index} moveRow={moveRow} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        // Special handling for case_name cells
                                        if (cell.column.id === 'case_name' && row.original.case_id) {
                                            return (
                                                <div
                                                    {...cell.getCellProps()}
                                                    className="td"
                                                    style={{
                                                        ...cell.getCellProps().style,
                                                        position: cell.column.sticky ? 'sticky' : 'relative',
                                                        left: cell.column.sticky ? 0 : 'auto',
                                                        background: 'white',
                                                        zIndex: cell.column.sticky ? 1 : 0
                                                    }}
                                                >

                                                    <Badge variant={'case_name'} px={2} py={1} borderRadius="4px" fontSize={'14px'} cursor={'pointer'} onClick={() => navigate(`/case/${row.original.case_id}`)}>
                                                        <HStack spacing={1}>
                                                            <DocIcon style={{ display: 'inline-block', marginRight: '4px' }} height={16} width={16} fill='#000' />
                                                            <Text color={'gray.900'}>{cell.value}</Text>
                                                        </HStack>
                                                    </Badge>
                                                </div>
                                            );
                                        }

                                        // Special handling for fields that need to be collapsed into badges
                                        if (FIELDS_TO_COLLAPSE.includes(cell.column.id) && typeof cell.value === 'object') {
                                            const isExpanded = isCellExpanded(row.original.case_id, cell.column.id);
                                            
                                            return (
                                                <div
                                                    {...cell.getCellProps()}
                                                    className="td"
                                                    style={{
                                                        ...cell.getCellProps().style,
                                                        position: cell.column.sticky ? 'sticky' : 'relative',
                                                        left: cell.column.sticky ? 0 : 'auto',
                                                        background: 'white',
                                                        zIndex: cell.column.sticky ? 1 : 0,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => toggleCellExpansion(row.original.case_id, cell.column.id)}
                                                >
                                                    <HStack 
                                                        spacing={2} 
                                                        flexWrap="wrap"
                                                        style={{
                                                            whiteSpace: isExpanded ? 'normal' : 'nowrap',
                                                            overflow: isExpanded ? 'visible' : 'hidden',
                                                            textOverflow: isExpanded ? 'initial' : 'ellipsis',
                                                            maxHeight: isExpanded ? 'none' : '1.5em'
                                                        }}
                                                    >
                                                        {Object.entries(cell.value).map(([key, values]) => (
                                                            <Text color={'gray.700'}>{key}{values.length > 0 ? ` → ${values.join(', ')}` : ''}</Text>
                                                        ))}
                                                    </HStack>
                                                </div>
                                            );
                                        }

                                        // Handle custom column cells with potential references
                                        const cellKey = `${row.original.case_id}_${cell.column.id}`;
                                        const isCustomValueCell = customColumns.some(col => 
                                            col.accessor === cell.column.id && (col.type === 'value' || col.type === 'text')
                                        );
                                        const showReferencesButton = isCustomValueCell && hoveredCell === cellKey;
                                        
                                        return (
                                            <div
                                                {...cell.getCellProps()}
                                                className="td"
                                                style={{
                                                    ...cell.getCellProps().style,
                                                    position: cell.column.sticky ? 'sticky' : 'relative',
                                                    left: cell.column.sticky ? 0 : 'auto',
                                                    background: 'white',
                                                    zIndex: cell.column.sticky ? 1 : 0,
                                                    cursor: 'pointer',
                                                    position: 'relative' // For the absolute positioned button
                                                }}
                                                onClick={() => toggleCellExpansion(row.original.case_id, cell.column.id)}
                                                onMouseEnter={() => {
                                                    // Only set hoveredCell for custom value columns with citations
                                                    if (isCustomValueCell && customColumnData[cellKey]?.citations) {
                                                        setHoveredCell(cellKey);
                                                    }
                                                }}
                                                onMouseLeave={() => setHoveredCell(null)}
                                            >
                                                <div 
                                                    style={{
                                                        whiteSpace: isCellExpanded(row.original.case_id, cell.column.id) ? 'normal' : 'nowrap',
                                                        overflow: isCellExpanded(row.original.case_id, cell.column.id) ? 'visible' : 'hidden',
                                                        textOverflow: isCellExpanded(row.original.case_id, cell.column.id) ? 'initial' : 'ellipsis',
                                                        maxHeight: isCellExpanded(row.original.case_id, cell.column.id) ? 'none' : '1.5em'
                                                    }}
                                                >
                                                    {cell.render('Cell')}
                                                </div>
                                                
                                                {showReferencesButton && (
                                                    <Button 
                                                        size="sm"
                                                        position="absolute"
                                                        top="50%"
                                                        right="8px"
                                                        transform="translateY(-50%)"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenReferencesModal(
                                                                row.original.case_id, 
                                                                cell.column.id
                                                            );
                                                        }}
                                                        isLoading={isLoadingReferences}
                                                        className="show-references-button"
                                                    >
                                                        Sources
                                                    </Button>
                                                )}
                                            </div>
                                        );
                                    })}
                                </DraggableRow>
                            );
                        })}
                    </div>
                </Box>
            </Container>
            <DashboardReferencesModal
                isOpen={isReferencesModalOpen}
                onClose={() => setIsReferencesModalOpen(false)}
                references={references}
            />
        </DndProvider>
    );
};

export default DashboardSheet;
