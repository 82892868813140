import React, { useState, useEffect, useRef, memo } from 'react';
import { Box, Text, VStack, Button, Textarea, HStack } from '@chakra-ui/react';
import { Handle, Position } from 'reactflow';

const InstructionNode = ({ data }) => {
    const nodeRef = useRef(null);
    const [instruction, setInstruction] = useState(data.instruction || '');
    const [isEditing, setIsEditing] = useState(!data.instruction);

    // Report node dimensions for parent component's positioning calculations
    useEffect(() => {
        if (nodeRef.current && data.onNodeResize) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const height = entry.contentRect.height;
                    data.onNodeResize(data.id, height);
                }
            });
            
            // Report initial dimensions
            if (nodeRef.current) {
                const initialHeight = nodeRef.current.offsetHeight;
                data.onNodeResize(data.id, initialHeight);
            }
            
            resizeObserver.observe(nodeRef.current);
            
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [data]);

    const handleSave = () => {
        if (instruction.trim()) {
            data.onSave(instruction);
            setIsEditing(false);
        }
    };

    return (
        <Box
            className="instruction-node"
            bg="white"
            boxShadow="sm"
            borderRadius="md"
            p={4}
            minWidth="300px"
            maxWidth="500px"
            ref={nodeRef}
        >
            <Handle style={{ backgroundColor: '#00B870'}} type="target" position={Position.Left} />
            <Handle style={{ backgroundColor: '#00B870'}} type="source" position={Position.Right} />
            
            <VStack align="stretch" spacing={3}>
                {isEditing ? (
                    <>
                        <Textarea
                            value={instruction}
                            onChange={(e) => setInstruction(e.target.value)}
                            placeholder="Enter instruction here..."
                            rows={4}
                        />
                        <HStack justifyContent="flex-end">
                            <Button colorScheme="green" onClick={handleSave}>
                                Save
                            </Button>
                        </HStack>
                    </>
                ) : (
                    <>
                        <Text>{instruction}</Text>
                        <Button size="sm" onClick={() => setIsEditing(true)}>
                            Edit
                        </Button>
                    </>
                )}
            </VStack>
        </Box>
    );
};

export default memo(InstructionNode);