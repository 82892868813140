import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Heading,
  HStack,
  Button,
  Text,
  Flex,
  Card,
  Avatar
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../images/add.svg'
import moment from 'moment';

const DashboardOverview = () => {
  const [dashboards, setDashboards] = useState([]);
  const navigate = useNavigate();

  const fetchDashboards = async () => {
    const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_dashboards`);
    setDashboards(response.data.dashboards);
  };

  useEffect(() => {
    fetchDashboards();
  }, []);

  const DashboardCard = ({ dashboard }) => {
    return (
      <Card p={4} onClick={() => navigate(`/dashboard/${dashboard._id}`)}>
        <Text fontSize={'1.2rem'} fontWeight={'600'} mb={2}>{dashboard.name}</Text>
        <HStack spacing={4}>
        <HStack>
          <Avatar size={'xs'} src={dashboard.creator_profile_picture} />
          <Text color={'gray.800'}>
            {dashboard.creator_name}
          </Text>
          </HStack>
          <Text fontSize={'1.2rem'} fontWeight={'600'}>·</Text>
          <Text>{moment(dashboard.created_at * 1000).fromNow()}</Text>
        </HStack>
      </Card>
    );
  };

  return (
    <Container maxW="container.xl" py={8}>
      <HStack justify="space-between" align="center" mb={8}>
        <Heading size="lg" fontFamily={'Garnett'} fontWeight={'600'} color={'gray.900'}>
          Dashboard Overview
        </Heading>
        <Button variant={'secondary'} onClick={() => navigate('/dashboard/new')} leftIcon={<PlusIcon />}>
          New Dashboard
        </Button>
      </HStack>
      <Flex direction="column" gap={4}>
        {dashboards && dashboards.map((dashboard) => (
          <DashboardCard key={dashboard.id} dashboard={dashboard} />
        ))}
      </Flex>
    </Container>
  );
};

export default DashboardOverview;
