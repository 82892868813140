import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepIndicator, StepStatus, StepIcon, StepNumber, StepSeparator, StepTitle, StepDescription, Card, Button, HStack, VStack, Text } from '@chakra-ui/react';
import { ReactComponent as UploadIcon } from '../../images/upload.svg';
import PreviewCaseFileModal from './PreviewCaseFileModal';
import { renderAsync } from 'docx-preview';
import axios from 'axios';

import { ReactComponent as CheckFilledIcon } from '../../images/check_filled.svg';
import { ReactComponent as LabelIcon } from '../../images/label.svg';

const CaseFileUploadWizard = ({ caseID, onFinish, processedTagsFromPleading, caseFiles }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [numPages, setNumPages] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const previewRef = React.useRef(null);
    const [selectedParty, setSelectedParty] = useState(null);
    const [uploadedClaimantFileName, setUploadedClaimantFileName] = useState(null);
    const [uploadedRespondentFileName, setUploadedRespondentFileName] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [claimantTags, setClaimantTags] = useState([]);
    const [respondentTags, setRespondentTags] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState({
        claimant: [],
        respondent: []
    });

    const [isPleadingProcessing, setIsPleadingProcessing] = useState(false);

    const [shouldShowTags, setShouldShowTags] = useState(false);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [usedTags, setUsedTags] = useState(new Set());

    const [pleadingFiles, setPleadingFiles] = useState({
        claimant: null,
        respondent: null
    });

    useEffect(() => {
        if (caseFiles && caseFiles.length > 0) {
            // Initialize uploaded files and pleadings
            const newUploadedFiles = { claimant: [], respondent: [] };
            let newPleadingFiles = { claimant: null, respondent: null };

            caseFiles.forEach(file => {
                if (file.party === 'claimant') {
                    if (file.tag === 'Claimant Pleading') {
                        newPleadingFiles.claimant = file.original_filename;
                        setUploadedClaimantFileName(file.original_filename);
                    } else {
                        newUploadedFiles.claimant.push({
                            name: file.original_filename,
                            tag: file.tag
                        });
                    }
                } else if (file.party === 'respondent') {
                    if (file.tag === 'Respondent Pleading') {
                        newPleadingFiles.respondent = file.original_filename;
                        setUploadedRespondentFileName(file.original_filename);
                    } else {
                        newUploadedFiles.respondent.push({
                            name: file.original_filename,
                            tag: file.tag
                        });
                    }
                }
            });

            setUploadedFiles(newUploadedFiles);
            setPleadingFiles(newPleadingFiles);

            // Set tags if they exist
            if (processedTagsFromPleading) {
                setClaimantTags(processedTagsFromPleading.claimant_tags || []);
                setRespondentTags(processedTagsFromPleading.respondent_tags || []);
            }

            // Determine which step to show
            if (newPleadingFiles.claimant && newPleadingFiles.respondent) {
                // If we have both pleadings and some documents
                if (newUploadedFiles.claimant.length > 0 || newUploadedFiles.respondent.length > 0) {
                    setCurrentStep(newUploadedFiles.claimant.length > 0 ? 2 : 1);
                } else {
                    setCurrentStep(1);
                }
            }
        }
    }, [caseFiles, processedTagsFromPleading]);

    useEffect(() => {
        if (currentStep === 1 || currentStep === 2) {
            setShouldShowTags(true);
        }
    }, [currentStep]);

    const handleFileSelect = async (files, type) => {
        if (!files || files.length === 0) {
            console.log("No files selected");
            return;
        }

        setSelectedParty(type);

        // If it's step 0 (pleadings), handle single file
        if (currentStep === 0) {
            const file = files[0];
            setSelectedFile(file);
            setSelectedFiles([file]);
        } else {
            // For multiple files
            setSelectedFiles(files);
            setSelectedFile(files[0]);
            setCurrentFileIndex(0);
        }

        try {
            setIsModalOpen(true);
            const firstFile = files[0];
            if (firstFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                const arrayBuffer = await firstFile.arrayBuffer();
                await renderAsync(arrayBuffer, previewRef.current);
            }
        } catch (error) {
            console.error("Error processing file:", error);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleFileUpload = async (selectedTag) => {
        if (!selectedFiles || !selectedFiles.length || !selectedParty) return;
        setIsUploading(true);

        const currentFile = selectedFiles[currentFileIndex];
        const formData = new FormData();
        formData.append('file', currentFile);
        formData.append('case_id', caseID);
        formData.append('party', selectedParty);

        const tag = currentStep === 0 ?
            (selectedParty === 'claimant' ? 'Claimant Pleading' : 'Respondent Pleading') : selectedTag;
        formData.append('tag', tag);

        try {
            await axios.put(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/upload_casefile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (currentStep !== 0) {
                setUsedTags(prev => new Set([...prev, selectedTag]));
                if (selectedParty === 'claimant') {
                    setUploadedFiles(prev => ({
                        ...prev,
                        claimant: [...prev.claimant, { name: currentFile.name, tag: selectedTag }]
                    }));
                } else {
                    setUploadedFiles(prev => ({
                        ...prev,
                        respondent: [...prev.respondent, { name: currentFile.name, tag: selectedTag }]
                    }));
                }
            } else {
                if (selectedParty === 'claimant') {
                    setPleadingFiles(prev => ({ ...prev, claimant: currentFile.name }));
                    setUploadedClaimantFileName(currentFile.name);
                } else {
                    setPleadingFiles(prev => ({ ...prev, respondent: currentFile.name }));
                    setUploadedRespondentFileName(currentFile.name);
                }
            }

            // If there are more files to upload
            if (currentFileIndex < selectedFiles.length - 1) {
                setIsUploading(false);
                setCurrentFileIndex(prev => prev + 1);
                const nextFile = selectedFiles[currentFileIndex + 1];
                setSelectedFile(nextFile);
                await updatePreview(nextFile);
            } else {
                // Reset everything when all files are uploaded
                setIsUploading(false);
                handleCloseModal();
                setSelectedFile(null);
                setSelectedFiles([]);
                setCurrentFileIndex(0);
                setUsedTags(new Set());
            }

        } catch (error) {
            console.error('Error uploading file:', error);
            setIsUploading(false);
        }
    };

    const handleProcessPleadingsForTags = async () => {
        try {
            setIsPleadingProcessing(true);
            const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/process_pleadings`;
            const response = await axios.post(url, {
                case_id: caseID
            });
            const { claimant, respondent } = response.data;
            setClaimantTags(claimant);
            setRespondentTags(respondent);
            setCurrentStep(1);
            setIsPleadingProcessing(false);
        } catch (error) {
            console.error('Error processing pleadings:', error);
            setIsPleadingProcessing(false);
        }
    }

    const updatePreview = async (file) => {
        if (!file) return;
        
        try {
            if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                const arrayBuffer = await file.arrayBuffer();
                await renderAsync(arrayBuffer, previewRef.current);
            }
            // For PDFs, we just need to update the selectedFile state as the Document component 
            // will handle the rendering
        } catch (error) {
            console.error("Error updating preview:", error);
        }
    };

    const canNavigateToStep = (stepIndex) => {
        if (stepIndex === 0) return true;
        if (stepIndex === 1 || stepIndex === 2) {
            return Boolean(uploadedClaimantFileName && uploadedRespondentFileName);
        }
        return false;
    };

    const handleStepClick = (stepIndex) => {
        if (canNavigateToStep(stepIndex)) {
            setCurrentStep(stepIndex);
        }
    };

    const handleClaimantNext = () => {
        if (uploadedFiles.claimant.length > 0) {
            setCurrentStep(2);
        }
    };
    

    const steps = [
        {
            title: 'Add Pleadings',
            description: 'Upload claimant and respondent pleadings',
            content: (
                <Card width={'100%'} px={4} py={8} mt={2} variant={'default'}>
                    <VStack spacing={4} align="stretch" width="50%" mx="auto">
                        <input
                            type="file"
                            id="claimant-pleading-file-upload"
                            hidden
                            accept=".pdf,.docx"
                            onChange={(e) => { if (e.target.files && e.target.files.length > 0) { handleFileSelect(e.target.files, 'claimant') } }}
                        />
                        <input
                            type="file"
                            id="respondent-pleading-file-upload"
                            hidden
                            accept=".pdf,.docx"
                            onChange={(e) => { if (e.target.files && e.target.files.length > 0) { handleFileSelect(e.target.files, 'respondent') } }}
                        />

                        {uploadedClaimantFileName ? (
                            <Card variant={'default_outline'} p={4} borderRadius="md">
                                <HStack justify="space-between" align="center">
                                    <HStack spacing={4}>
                                        <CheckFilledIcon fill='#00B870' />
                                        <Box>
                                            <HStack spacing={2} bg={'gray.200'} py={1} px={3} borderRadius={'md'} width={'fit-content'}>
                                                <LabelIcon height={'20px'} width={'20px'} fill='#2d3748' />
                                                <Text color={'gray.700'} fontSize={'1rem'} fontWeight={'600'}>Claimant Pleading</Text>
                                            </HStack>
                                            <Text mt={2} color="gray.600">{uploadedClaimantFileName}</Text>
                                        </Box>
                                    </HStack>
                                </HStack>
                            </Card>
                        ) : (
                            <Button
                                variant="outline"
                                leftIcon={<UploadIcon fill={'#00995D'} />}
                                onClick={() => document.getElementById('claimant-pleading-file-upload').click()}
                            >
                                Upload Claimant Pleading
                            </Button>
                        )}

                        {uploadedRespondentFileName ? (
                            <Card variant={'default_outline'} p={4} borderRadius="md">
                                <HStack justify="space-between" align="center">
                                    <HStack spacing={4}>
                                        <CheckFilledIcon fill='#00B870' />
                                        <Box>
                                            <HStack spacing={2} bg={'gray.200'} py={1} px={3} borderRadius={'md'} width={'fit-content'}>
                                                <LabelIcon height={'20px'} width={'20px'} fill='#2d3748' />
                                                <Text color={'gray.700'} fontSize={'1rem'} fontWeight={'600'}>Respondent Pleading</Text>
                                            </HStack>
                                            <Text mt={2} color="gray.600">{uploadedRespondentFileName}</Text>
                                        </Box>
                                    </HStack>
                                </HStack>
                            </Card>
                        ) : (
                            <Button
                                variant="outline"
                                leftIcon={<UploadIcon fill={'#00995D'} />}
                                onClick={() => document.getElementById('respondent-pleading-file-upload').click()}
                            >
                                Upload Respondent Pleading
                            </Button>
                        )}
                        <Button isDisabled={!uploadedClaimantFileName} isLoading={isPleadingProcessing} variant={'default'} onClick={handleProcessPleadingsForTags}>Next</Button>
                    </VStack>
                </Card>
            )
        },
        {
            title: 'Add Claimant Documents',
            description: 'Upload supporting documents for the claimant',
            content: (
                <Card width={'100%'} px={4} py={8} mt={2} variant={'default'}>
                    <VStack spacing={4} align="stretch" width="50%" mx="auto">
                        <input
                            type="file"
                            id="claimant-document-upload"
                            hidden
                            multiple="multiple"
                            accept=".pdf,.docx"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    handleFileSelect(Array.from(e.target.files), 'claimant')
                                }
                            }}
                        />
                        <Button
                            variant="outline"
                            leftIcon={<UploadIcon fill={'#00995D'} />}
                            onClick={() => document.getElementById('claimant-document-upload').click()}
                        >
                            Upload Claimant Document
                        </Button>
                        {uploadedFiles.claimant.map((file, index) => (
                            <Card key={index} variant={'default_outline'} p={4} borderRadius="md">
                                <HStack justify="space-between" align="center">
                                    <Box>
                                        <Text fontWeight="medium" color="gray.700">{file.name}</Text>
                                        <Text color="gray.600">{file.tag}</Text>
                                    </Box>
                                </HStack>
                            </Card>
                        ))}
                        <Button 
                            variant={'default'} 
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClaimantNext();
                            }}
                            isDisabled={uploadedFiles.claimant.length === 0}
                        >
                            Next
                        </Button>
                    </VStack>
                </Card>
            )
        },
        {
            title: 'Add Respondent Documents',
            description: 'Upload supporting documents for the respondent',
            content: (
                <Card width={'100%'} px={4} py={8} mt={2} variant={'default'}>
                    <VStack spacing={4} align="stretch" width="50%" mx="auto">
                        <input
                            type="file"
                            id="respondent-document-upload"
                            hidden
                            multiple="multiple"
                            accept=".pdf,.docx"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    handleFileSelect(Array.from(e.target.files), 'respondent')
                                }
                            }}
                        />
                        <Button
                            variant="outline"
                            leftIcon={<UploadIcon fill={'#00995D'} />}
                            onClick={() => document.getElementById('respondent-document-upload').click()}
                        >
                            Upload Respondent Document
                        </Button>
                        {uploadedFiles.respondent.map((file, index) => (
                            <Card key={index} variant={'default_outline'} p={4} borderRadius="md">
                                <HStack justify="space-between" align="center">
                                    <Box>
                                        <Text fontWeight="medium" color="gray.700">{file.name}</Text>
                                        <Text color="gray.600">{file.tag}</Text>
                                    </Box>
                                </HStack>
                            </Card>
                        ))}
                        <HStack spacing={4} justify="space-between">
                            <Button variant={'outline'} onClick={() => setCurrentStep(1)}>Previous</Button>
                            <Button variant={'default'} onClick={onFinish}>Finish</Button>
                        </HStack>
                    </VStack>
                </Card>
            )
        }
    ];

    return (
        <Box p={8}>
            <Stepper variant={'default'} orientation='vertical' index={currentStep} gap='0'>
                {steps.map((step, index) => (
                    <Step 
                        width={'100%'} 
                        key={index} 
                        mt={index === 0 ? 0 : 4}
                        cursor={canNavigateToStep(index) ? 'pointer' : 'not-allowed'}
                        onClick={() => handleStepClick(index)}
                    >
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>
                        <Box width={'100%'} flexShrink='0'>
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>{step.description}</StepDescription>
                                {currentStep === index && step.content}
                        </Box>
                        {index < steps.length - 1 && <StepSeparator />}
                    </Step>
                ))}
            </Stepper>

            <PreviewCaseFileModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                selectedFile={selectedFile}
                caseName="Case Name"
                handleFileUpload={handleFileUpload}
                isUploading={isUploading}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                numPages={numPages}
                previewRef={previewRef}
                onDrop={handleFileSelect}
                onUploadSuccess={(file) => {
                    if (selectedParty === 'claimant') {
                        setUploadedClaimantFileName(file.name);
                    } else {
                        setUploadedRespondentFileName(file.name);
                    }
                }}
                currentStep={currentStep}
                shouldShowTags={shouldShowTags}
                tagList={currentStep === 1 ? claimantTags : respondentTags}
                usedTags={usedTags}
            />
        </Box>
    );
};

export default CaseFileUploadWizard;