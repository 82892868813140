import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  HStack,
  Image,
  useColorModeValue,
  useToast,
  Progress,
  Divider,
  Card
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

import VerifyMetadataModal from './VerifyMetadataModal';

import { ReactComponent as PdfIcon } from '../../images/pdf_icon.svg'
import { ReactComponent as DocxIcon } from '../../images/docx_icon.svg'
import { ReactComponent as DoubleCheckIcon } from '../../images/double_check.svg'
import { ReactComponent as CheckFilledIcon } from '../../images/check_filled.svg'
import { ReactComponent as ClaimantNameIcon } from '../../images/metadata_icons/claimant_name.svg'
import { ReactComponent as ClaimValueIcon } from '../../images/metadata_icons/claim_value.svg'
import { ReactComponent as NatureOfActionIcon } from '../../images/metadata_icons/nature_of_action.svg'
import { ReactComponent as HarmsAllegedIcon } from '../../images/metadata_icons/harms_alleged.svg'
import { ReactComponent as CategoryIcon } from '../../images/metadata_icons/category.svg'
import { ReactComponent as NatureOfComplainantIcon } from '../../images/metadata_icons/nature_of_complainant.svg'
import { ReactComponent as RelationshipOfComplainant } from '../../images/metadata_icons/relationship_of_complainant.svg'
import { ReactComponent as DesignationOfComplainant } from '../../images/metadata_icons/designation_of_complainant.svg'

const metadataIcons = {
  opposing_party_name: ClaimantNameIcon,
  claim_value_precise: ClaimValueIcon,
  claim_value_range: ClaimValueIcon,
  nature_of_action_l1: NatureOfActionIcon,
  nature_of_action_l2: NatureOfActionIcon,
  damages: HarmsAllegedIcon,
  category: CategoryIcon,
  subcategory: CategoryIcon,
  relationship_between_parties: RelationshipOfComplainant,
  relief_sought: DesignationOfComplainant
};

const OverviewTab = ({ caseID, caseDetails }) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [metadata, setMetadata] = useState(caseDetails.metadata || []);
  const [claimantPleadingFileID, setClaimantPleadingFileID] = useState(caseDetails.file_id);
  const [isVerifyMetadataModalOpen, setIsVerifyMetadataModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [loadingStep, setLoadingStep] = useState(0);
  const [loadingTime, setLoadingTime] = useState(0);
  const [thumbnailUrl, setThumbnailUrl] = useState(caseDetails.thumbnail_url);
  const [metadataPayload, setMetadataPayload] = useState(null);
  const [docPreview, setDocPreview] = useState(null);
  const toast = useToast();

  const loadingSteps = [
    { text: "Uploading document", duration: 3 },
    { text: "Creating an index", duration: 5 },
    { text: "Extracting key metadata", duration: 7 },
    { text: "Verifying results", duration: 5 },
  ];

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setLoadingTime((prevTime) => {
          const newTime = prevTime + 1;
          if (newTime >= loadingSteps[loadingStep].duration) {
            if (loadingStep < loadingSteps.length - 1) {
              setLoadingStep((prevStep) => prevStep + 1);
              return 0;
            } else {
              clearInterval(timer);
            }
          }
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isLoading, loadingStep]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      toast({
        title: "No file selected",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    setLoadingStep(0);
    setLoadingTime(0);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('case_id', caseID);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/extract_metadata`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.metadata) {
        setMetadata(response.data.metadata);
        setThumbnailUrl(response.data.thumbnail_url);
        setClaimantPleadingFileID(response.data.file_id);
        setIsUploaded(true);
      } else {
        throw new Error('No metadata received from the server');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      toast({
        title: "Error uploading file",
        description: error.response?.data?.error || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleMetadataAction = async (metadata, mode = 'reject') => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/fetch_doc_preview`,
        {
          case_id: caseID,
          file_id: claimantPleadingFileID
        },
        {
          responseType: 'arraybuffer'
        }
      );

      const docBlob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      });

      setDocPreview(docBlob);
      setMetadataPayload({
        fileID: claimantPleadingFileID,
        caseID: caseID,
        metadata: metadata,
        mode: mode,
        fieldIcon: metadataIcons[metadata.id]
      });

      setTimeout(() => {
        setIsVerifyMetadataModalOpen(true);
      }, 100);

    } catch (error) {
      console.error('Error fetching document preview:', error);
      toast({
        title: "Error fetching document preview",
        description: "Failed to load document preview. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleMetadataVerified = (fieldId) => {
    setMetadata(prevMetadata => 
      prevMetadata.map(item => 
        item.id === fieldId 
          ? { ...item, status: 'verified' }
          : item
      )
    );
  };

  const formatMetadataValue = (id, value) => {
    if (id === 'claim_value_precise') {
      return `₹${Number(value).toLocaleString('en-IN')}`;
    } else if ((id === 'damages' || id === 'relief_sought') && value) {
      let formattedValue = '';
      Object.entries(value).forEach(([category, items]) => {
        if (items.length > 0) {
          formattedValue += `${category}: ${items.join(', ')}\n`;
        }
      });
      return formattedValue.trim();
    } else if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  };

  if (!metadata) {
    return (
      <Box>
        <Box mb={4} p={3} borderRadius="md" borderWidth={1} bg={'green.50'}>
          <Flex justify={'space-between'}>
            <VStack spacing={0} align={'flex-start'}>
              <Text fontSize="lg" fontWeight="bold" color={'gray.800'}>
                Extract Metadata
              </Text>
              <Text>
                Upload a claimant pleading to extract key metadata.
              </Text>
            </VStack>
          </Flex>
        </Box>

        {file && (
          <Box bg={'white'} mb={4} py={3} px={5} borderWidth={1} borderRadius="md">
            <Flex align="center" justify={'space-between'}>
              <HStack>{file.type === 'application/pdf' ? (
                <PdfIcon width={42} height={42} mr={8} />
              ) : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                <DocxIcon width={42} height={42} mr={8} />
              ) : null}
                <Text ml={4} fontSize={'lg'} color={'gray.800'} maxWidth={'320px'}>{file.name}</Text></HStack>
              <Button variant={'default'} onClick={handleUpload} isLoading={isLoading}>
                Start
              </Button>
            </Flex>
            {isLoading && (
              <Box bg={'green.50'} py={4} px={8} mt={4} borderRadius={'md'}>
                <Box height="50px">
                  <AnimatePresence>
                    <motion.div
                      key={loadingStep}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Text color={'gray.800'} mb={2} fontSize={'1.1rem'}>
                        {loadingSteps[loadingStep].text}
                      </Text>
                    </motion.div>
                  </AnimatePresence>
                </Box>
                <Progress size="sm" borderRadius={'full'} isIndeterminate colorScheme="green" />
              </Box>
            )}
          </Box>
        )}

        {!file && <Button
          variant={'default'}
          onClick={() => {
            document.getElementById('fileInput').click();
          }}
          isLoading={isLoading}
        >
          Upload Claimant Pleading
        </Button>}

        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </Box>
    );
  }

  // Transform the metadata object into an array format for rendering
  const metadataArray = Array.isArray(metadata) 
    ? metadata.filter(item => item.id !== 'claim_value_range' && item.id !== 'opposing_party_name')
    : Object.entries(metadata)
        .filter(([key]) => key !== 'claim_value_range' && key !== 'opposing_party_name' && key !== 'nature_of_action_l2' && key !== 'nature_of_action_l1')
        // .filter(([key]) => key !== 'claim_value_range' && key !== 'opposing_party_name')
        .map(([key, value]) => ({
          id: key,
          field: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
          value: value,
          status: 'unactioned'
        }));

  return (
    <Flex direction={{ base: 'column', md: 'row' }} spacing={4}>
      <VStack flex={2} align="stretch" spacing={0}>
        <Text fontSize="lg" textTransform={'uppercase'} fontWeight="bold" mb={4} mt={2} ml={4}>
          Extracted Metadata
        </Text>
        {metadataArray.map((item, index) => (
          <Card bg={'transparent'} border={'none'} position={'relative'} key={item.id} px={5} pr={4} py={2} boxShadow={'none'}>
            <Flex justify="space-between" align="flex-start">
              <HStack spacing={4}>
                <Box bg={'#fff'} borderRadius={4} p={2} borderWidth={1} borderColor={'#d9d9d9'}>
                {metadataIcons[item.id] && React.createElement(metadataIcons[item.id], { width: 24, height: 24, fill: '#00B870' })}
                </Box>
                <VStack align="start" spacing={1}>
                  <HStack>
                    <Text>{item.field}</Text>
                  </HStack>
                  <Text color={'gray.800'} fontWeight={'600'} whiteSpace={'pre-wrap'}>
                    {formatMetadataValue(item.id, item.value)}
                  </Text>
                </VStack>
              </HStack>
               {item?.status === 'unactioned' ? <Box>
                <VStack justify={'flex-start'} align={'flex-start'} spacing={2} width="100%">
                  <Button
                    variant={'outline_secondary'}
                    leftIcon={<DoubleCheckIcon fill={'#00B870'} />}
                    color={'#00B870'}
                    size="sm"
                    onClick={() => handleMetadataAction(item, 'accept')}
                    width="100%"
                  >
                    Accept
                  </Button>
                  <Button
                    size="sm"
                    variant={'ghost_secondary'}
                    onClick={() => handleMetadataAction(item, 'reject')}
                    width="100%"
                  >
                    Fill Manually
                  </Button>
                </VStack>
              </Box> : <HStack mt={2}>
                <CheckFilledIcon fill={'#00B870'} />
                <Text>Verified</Text>
              </HStack>}
            </Flex>
            {index !== metadataArray.length - 1 && <Divider variant={'default_muted'} mt={2} />}
          </Card>
        ))}
      </VStack>
      <VerifyMetadataModal
        docPreview={docPreview}
        isOpen={isVerifyMetadataModalOpen}
        metadataPayload={metadataPayload}
        onClose={() => setIsVerifyMetadataModalOpen(false)}
        onMetadataVerified={handleMetadataVerified}
      />
    </Flex>
  );
};

export default OverviewTab;
