import React, { useEffect, useRef, memo } from 'react';
import { Box, Text, VStack, Divider, Badge, Heading, HStack, Button } from '@chakra-ui/react';
import { Handle, Position } from 'reactflow';
import { ReactComponent as AddInstructionIcon } from '../../images/add_instruction.svg'

const PleadingBlockNode = ({ data }) => {    
    const nodeRef = useRef(null);

    useEffect(() => {
        if (nodeRef.current && data.onNodeResize) {
            // Report initial height
            data.onNodeResize(data.id, nodeRef.current.offsetHeight);
            
            // Set up resize observer to report height changes
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    data.onNodeResize(data.id, entry.contentRect.height);
                }
            });
            
            resizeObserver.observe(nodeRef.current);
            
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [data]);


    return (
        <Box
            className="pleading-block-node"
            bg="white"
            boxShadow="sm"
            borderRadius="md"
            p={4}
            minWidth="300px"
            maxWidth="500px"
            ref={nodeRef}
        >
            <VStack align="stretch" spacing={3} mt={2}>
                <Box pb={1} px={3} >
                    <Heading as="h3" size="md" color="#2c3e50">{data.title}</Heading>
                </Box>

                <Box px={3}>
                    <Text>{data.summary}</Text>
                </Box>

                <Divider mt={2} />

                <HStack>
                    <Button
                        variant={'ghost_secondary'}
                        leftIcon={<AddInstructionIcon fill='#00B870' />}
                        onClick={() => data.onAddInstruction(data.id)}
                    >
                        Add Instruction
                    </Button>
                </HStack>
            </VStack>
            <Handle style={{ backgroundColor: '#00B870', height: '12px', width: '12px', borderRadius: '50%', border: 'none'}} position='right' />
        </Box>
    );
};

export default memo(PleadingBlockNode);