/**
 * DashboardReferencesModal Component
 * 
 * A modal component that displays references for dashboard cells.
 * 
 * @param {Object} props
 * @param {boolean} props.isOpen - Controls whether the modal is displayed
 * @param {function} props.onClose - Function to call when the modal is closed
 * @param {Object} props.references - Object containing reference data with text, references array, and case_id
 */

import React, { useEffect, useRef, useState } from 'react';
import { renderAsync } from 'docx-preview';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Box,
    VStack,
    Divider,
    Badge
} from '@chakra-ui/react';

import axios from 'axios';
import './index.scss';
import { highlightAndScrollToText, removeAllSpaces } from './../../helpers/DocHighlightHelper'

const DashboardReferencesModal = ({ isOpen, onClose, references = {} }) => {
    const docPreviewRef = useRef(null);
    const [documentData, setDocumentData] = useState(null);

    useEffect(() => {
        console.log('references', references);
        fetchDocument();
    }, [references]);

    useEffect(() => {
        if (docPreviewRef.current && documentData) {
            renderAsync(documentData, docPreviewRef.current)
                .then(() => {
                    if (references.references && references.references.length > 0) {
                        goToDoc(references.references[0].text);
                    }
                })
                .catch(err => console.error(err));
        }
    }, [documentData, references]);

    const goToDoc = (doc_text) => {
        setTimeout(() => {
            const processedText = removeAllSpaces(doc_text);
            highlightAndScrollToText(docPreviewRef.current, processedText);
        }, 10);
    };

    const fetchDocument = async () => {
        if (!references.references || references.references.length === 0) return;
        
        const docId = references.references[0].doc_id;
        if (!docId) return;

        const [fileId, versionId] = docId.split('_%%_');

        try {
            const requestObject = {
                case_id: references.case_id,
                // file_id: fileId,
                // version_id: versionId
                doc_id: docId
            }
            console.log('Fetching document:', requestObject);
            console.log('Fetching document:', references);

            const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/stream_casefile`, requestObject, {
                responseType: 'arraybuffer'
            });

            setDocumentData(response.data);
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };
    // Format the text with citations
    const formatTextWithCitations = () => {
        if (!references.text) return '';
        
        let resultText = references.text;
        const referenceMap = new Map();
        
        if (references.references && references.references.length > 0) {
            references.references.forEach(reference => {
                const { id, doc_id, text } = reference;
                referenceMap.set(id, { doc_id, text });
                
                const refRegex = new RegExp(`<ref id='${id}'>(.*?)</ref>`, 'g');
                resultText = resultText.replace(refRegex, (match, p1) => {
                    return `${p1}<span class="reference-id" data-reference-id="${id}">${id}</span>`;
                });
            });
        }
        
        return resultText;
    };

    const handleReferenceClick = (e) => {
        if (e.target.classList.contains('reference-id')) {
            const referenceId = e.target.getAttribute('data-reference-id');
            const reference = references.references.find(ref => ref.id === referenceId);
            
            if (reference) {
                goToDoc(reference.text);
            }
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Document References</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box 
                        mb={4} 
                        p={3} 
                        borderWidth="1px" 
                        borderRadius="md" 
                        bg="gray.50"
                        onClick={handleReferenceClick}
                        className="dashboard-references-text-container"
                    >
                        <div 
                            className="references-container"
                            dangerouslySetInnerHTML={{ __html: formatTextWithCitations() }}
                        />
                    </Box>
                    
                    <Divider my={3} />
                    
                    <Box
                        className="dashboard-references-doc-preview"
                        height={'calc(100vh - 500px)'}
                        overflowY={'auto'}
                        ref={docPreviewRef}
                    ></Box>
                </ModalBody>

                <ModalFooter>
                    <Button variant="default" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DashboardReferencesModal;
