import React, { useEffect, useRef } from 'react';
import { Handle } from 'reactflow';
import { Box, Text, VStack, Heading, Divider } from '@chakra-ui/react';

const ExcerptNode = ({ data, isConnectable }) => {
    const nodeRef = useRef(null);

    useEffect(() => {
        if (nodeRef.current && data.onNodeResize) {
            // Report initial height
            data.onNodeResize(data.id, nodeRef.current.offsetHeight);

            // Set up resize observer to report height changes
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    data.onNodeResize(data.id, entry.contentRect.height);
                }
            });

            resizeObserver.observe(nodeRef.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [data]);


    return (
        <Box
            bg="white"
            p={4}
            borderRadius="md"
            boxShadow="md"
            width="400px"
            borderWidth="1px"
            borderColor="green.200"
            ref={nodeRef}
        >
            <Handle
                type="target"
                position="left"
                style={{ background: '#00B870' }}
                isConnectable={isConnectable}
            />

            <VStack align="stretch" spacing={3}>
                <Heading size="sm" color="green.700">{data.title}</Heading>
                <Divider />
                <Text fontSize="sm" fontStyle="italic" color="gray.600">
                    {data.summary}
                </Text>
                <Box maxH="200px" overflowY="auto" py={2}>
                    {data.sentences.map((sentence, index) => (
                        <Text key={index} fontSize="sm" mb={2} p={2} bg="green.50" borderRadius="md">
                            {sentence}
                        </Text>
                    ))}
                </Box>
            </VStack>
        </Box>
    );
};

export default ExcerptNode;