import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip, Avatar, Flex, Menu, MenuButton, MenuList, MenuItem, VStack, Divider } from '@chakra-ui/react';

import { AuthContext } from '../helpers/AuthContext';

import { useGateValue } from '@statsig/react-bindings'

import AllCasesPageIcon from './PageIcons/AllCasesPageIcon';
import DashboardPageIcon from './PageIcons/DashboardPageIcon';
import SettingsPageIcon from './PageIcons/SettingsPageIcon';
import TriagePageIcon from './PageIcons/TriagePageIcon';
import ActionsPageIcon from './PageIcons/ActionsPageIcon';

const paths = [
    // {
    //     key: 'triage',
    //     route: '/triage',
    //     title: 'Triage',
    //     icon_name: 'TriagePageIcon',
    //     activeRoutes: ['/triage', '/new-triage-label']
    // },
    {
        key: 'all_cases',
        route: '/case_list',
        title: 'Case List',
        icon_name: 'AllCasesPageIcon',
        activeRoutes: ['/case_list', '/case', '/dispute_analysis']
    },
    {
        key: 'dashboard',
        route: '/dashboard',
        title: 'Dashboard',
        icon_name: 'TriagePageIcon',
        activeRoutes: ['/dashboard']
    },
    {
        key: 'actions',
        route: '/actions',
        title: 'Actions',
        icon_name: 'ActionsPageIcon',
        activeRoutes: ['/actions']
    },
];

const hideHeaderPaths = ['/', '/logout', '/join', '/verify', '/onboarding']

const NavigationHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeItem, setActiveItem] = useState(paths[0].key);
    const [shouldHideHeader, setShouldHideHeader] = useState(false)
    const { userData } = useContext(AuthContext);
    const isTriageEnabled = useGateValue("triage_navigation");
    const isReportsEnabled = useGateValue("reports_navigation");
    const isActionsEnabled = useGateValue("actions_navigation");

    useEffect(() => {
        const url = location.pathname;
        setShouldHideHeader(hideHeaderPaths.includes(url))
        let path_key = "";
        paths.forEach((path) => {
            if (path.activeRoutes.some(route => url.startsWith(route))) {
                path_key = path.key;
            }
        })
        setActiveItem(path_key)
    }, [location.pathname])

    const handleNavigation = (route, key) => {
        setActiveItem(key);
        navigate(route);
    };

    const renderIcon = (iconName, state) => {
        switch (iconName) {
            case 'TriagePageIcon':
                return isTriageEnabled ? <TriagePageIcon isActive={state === 'active'} /> : null;
            case 'AllCasesPageIcon':
                return <AllCasesPageIcon isActive={state === 'active'} />;
            case 'DashboardPageIcon':
                return isReportsEnabled ? <DashboardPageIcon isActive={state === 'active'} /> : null;
            case 'ActionsPageIcon':
                return isActionsEnabled ? <ActionsPageIcon isActive={state === 'active'} /> : null;
            case 'SettingsPageIcon':
                return <SettingsPageIcon isActive={state === 'active'} />;
            default:
                return null;
        }
    };

    return (
        <>
            {!shouldHideHeader && (
                <div className="navigation-header">
                    <div className="header-logo"></div>
                    <Flex py={5} alignItems={'center'} flexDirection={'column'} align={'center'} justify={'space-between'}>
                        <nav className="navigation-items">
                            {paths.map(({ key, route, title, icon_name }) => (
                                <Tooltip key={key} label={title} placement="right">
                                    <div
                                        className={`nav-item ${activeItem === key ? 'active' : ''}`}
                                        onClick={() => handleNavigation(route, key)}
                                    >
                                        {renderIcon(icon_name, activeItem === key ? 'active' : 'inactive')}
                                    </div>
                                </Tooltip>
                            ))}
                        </nav>

                        <VStack spacing={8} pb={6}>
                            <Divider variant={'default_strong'} />
                            <Tooltip label="Settings" placement="right">
                                <div
                                    className={`nav-item ${activeItem === 'settings' ? 'active' : ''}`}
                                    onClick={() => handleNavigation('/settings', 'settings')}
                                >
                                    {renderIcon('SettingsPageIcon', activeItem === 'settings' ? 'active' : 'inactive')}
                                </div>
                            </Tooltip>

                            <Menu placement="right">
                                <MenuButton as={Avatar} size="md" src={userData?.profile_picture_url} cursor="pointer" />
                                <MenuList>
                                    <MenuItem fontFamily={'Inter'} onClick={() => navigate('/support')}>Get Support</MenuItem>
                                    <MenuItem fontFamily={'Inter'} color={'red.500'} onClick={() => navigate('/logout')}>Logout</MenuItem>
                                </MenuList>
                            </Menu>
                        </VStack>
                    </Flex>
                </div>
            )}
        </>
    );
}

export default NavigationHeader;
